import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Close_DashMiltimediaDrawer } from '@mirage/analytics/events/types/close_dash_miltimedia_drawer';
import { atom, useAtom } from 'jotai';

import type { SearchResult } from '@mirage/shared/search/search-result';

export interface MediaPagination {
  index: number;
  count: number;
  next?: () => void;
  prev?: () => void;
}

const selectedMultimediaSearchResult = atom<SearchResult | null>(null);
const mediaPaginationAtom = atom<MediaPagination | undefined>(undefined);
export const useSelectedMultimediaSearchResult = () => {
  const { reportPapEvent, searchSessionManager } = useMirageAnalyticsContext();
  const [result, setSelectedResult] = useAtom(selectedMultimediaSearchResult);
  const [pagination, setPagination] = useAtom(mediaPaginationAtom);

  const closeMultimediaSearchResult = () => {
    setSelectedResult(null);
    reportPapEvent(
      PAP_Close_DashMiltimediaDrawer({
        resultUuid: result?.uuid,
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      }),
    );
  };
  const openMultimediaSearchResult = (
    result: SearchResult,
    pagination?: MediaPagination,
  ) => {
    setPagination(pagination);
    setSelectedResult(result);
    reportPapEvent(
      PAP_Close_DashMiltimediaDrawer({
        resultUuid: result?.uuid,
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      }),
    );
  };
  return {
    result,
    pagination,
    openMultimediaSearchResult,
    closeMultimediaSearchResult,
  };
};
