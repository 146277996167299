import { enforceExhaustive } from '@mirage/growth/util';
import { callApiV2 } from '@mirage/service-dbx-api';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useCallback, useReducer, useState } from 'react';
import { DashInviteFormSource } from '../types';
import { isValidInvitee } from '../utils/isValidInvitee';

import type {
  IndividualInviteFormContact,
  InviteFormContact,
  InviteWholeTeamFormContact,
} from '../types';
import type { dash_growth } from '@dropbox/api-v2-client/types/dropbox_types';

export interface InviteFormSubmissionResult {
  successfullyInvitedEmails: string[];
  successfullySuggestedEmails: string[];
  attemptedEmails?: string[];
  failedEmails: string[];
}

type UseSubmitReturnType =
  | {
      handleSubmit: () => void;
      submissionStatus: 'submitting' | null;
      submissionResult: null;
    }
  | {
      handleSubmit: () => void;
      submissionStatus: 'submitted';
      submissionResult: InviteFormSubmissionResult;
    };

const initialState: InviteFormSubmissionResult = {
  successfullyInvitedEmails: [],
  successfullySuggestedEmails: [],
  attemptedEmails: [],
  failedEmails: [],
};

type SubmitInviteFormAction =
  | { type: 'reset' }
  | {
      type: 'attempt';
      payload: Pick<InviteFormSubmissionResult, 'attemptedEmails'>;
    }
  | { type: 'fail' }
  | {
      type: 'receive_response';
      payload: Omit<InviteFormSubmissionResult, 'attemptedEmails'>;
    };

export const parseInviteUsersResponseInviteResult = (
  inviteResult: dash_growth.DashTeamInviteeResult[],
): Omit<InviteFormSubmissionResult, 'attemptedEmails'> =>
  inviteResult.reduce<Omit<InviteFormSubmissionResult, 'attemptedEmails'>>(
    (acc, invitee) => {
      if (invitee.email) {
        if (
          invitee.invite_result?.['.tag'] &&
          ['invite_result_invited', 'invite_result_invited_async'].includes(
            invitee.invite_result?.['.tag'],
          )
        ) {
          acc.successfullyInvitedEmails.push(invitee.email);
        } else if (
          invitee.invite_result?.['.tag'] === 'invite_result_suggested'
        ) {
          acc.successfullySuggestedEmails.push(invitee.email);
        } else {
          acc.failedEmails.push(invitee.email);
        }
      }
      return acc;
    },
    {
      successfullyInvitedEmails: [],
      successfullySuggestedEmails: [],
      failedEmails: [],
    },
  );

const submitInviteFormReducer = (
  state: InviteFormSubmissionResult,
  action: SubmitInviteFormAction,
): InviteFormSubmissionResult => {
  switch (action.type) {
    case 'reset':
      return initialState;
    case 'attempt':
      return {
        ...state,
        // FYI: while API is still returning hardcoded data, this will only be the set of emails that are requested via the UI, not the hardcoded ones
        attemptedEmails: action.payload.attemptedEmails,
      };
    case 'fail':
      return {
        ...state,
        failedEmails: state.attemptedEmails ? state.attemptedEmails : [],
      };
    case 'receive_response':
      return {
        ...state,
        successfullyInvitedEmails: action.payload.successfullyInvitedEmails,
        successfullySuggestedEmails: action.payload.successfullySuggestedEmails,
        failedEmails: action.payload.failedEmails,
      };
  }
};

export const useSubmitInviteForm = ({
  canInviteAllTeamMembers,
  canAssignRoles,
  canInviteWithOptionalMessage,
  customMessage,
  dashInviteFormSource,
  individualContacts,
  teamContacts,
  isValidForm,
}: {
  canInviteAllTeamMembers: boolean;
  canAssignRoles: boolean;
  canInviteWithOptionalMessage: boolean;
  customMessage?: string;
  dashInviteFormSource: DashInviteFormSource;
  individualContacts: Readonly<IndividualInviteFormContact[]>;
  teamContacts: Readonly<InviteWholeTeamFormContact[]>;
  isValidForm: boolean;
}): UseSubmitReturnType => {
  const [state, dispatch] = useReducer(submitInviteFormReducer, initialState);
  const [submissionStatus, setSubmissionStatus] = useState<
    'submitting' | 'submitted' | null
  >(null);

  let inviteSource: dash_growth.DashInviteSource;
  switch (dashInviteFormSource) {
    case DashInviteFormSource.DASH_HOMEPAGE_MODAL:
      inviteSource = {
        '.tag': 'invite_source_dash_homepage_modal',
      };
      break;
    case DashInviteFormSource.DASH_NEW_TEAM_ONBOARDING:
      inviteSource = {
        '.tag': 'invite_source_dash_new_team_onboarding',
      };
      break;
    default:
      enforceExhaustive(dashInviteFormSource);
  }

  let dashInvitePlatform: dash_growth.DashInvitePlatform;
  switch (EnvCtx.surface) {
    case 'web':
      dashInvitePlatform = {
        '.tag': 'invite_platform_web',
      };
      break;
    case 'desktop':
      dashInvitePlatform = {
        '.tag': 'invite_platform_desktop',
      };
      break;
    default:
      dashInvitePlatform = {
        '.tag': 'other',
      };
      break;
  }

  const handleSubmit = useCallback(() => {
    if (!isValidForm || submissionStatus) {
      return;
    }
    dispatch({ type: 'reset' });
    const validInvitees: (InviteFormContact & { email: string })[] = [
      ...individualContacts,
      ...teamContacts,
    ].filter(isValidInvitee);

    const requestInvitees = validInvitees.map((contact) => {
      const roleType: dash_growth.DashTeamRoleType | undefined = contact.newRole
        ? {
            '.tag':
              contact.newRole === 'Co-admin'
                ? 'dash_team_admin'
                : 'dash_team_member',
          }
        : undefined;
      return {
        email: contact.email,
        role: canAssignRoles ? roleType : undefined,
        customMessage,
      };
    });

    dispatch({
      type: 'attempt',
      payload: {
        attemptedEmails: requestInvitees.map((invitee) => invitee.email),
      },
    });
    setSubmissionStatus('submitting');

    callApiV2('dashGrowthTeamInvitesInviteUsers', {
      invitees: requestInvitees,
      platform: dashInvitePlatform,
      invite_source: inviteSource,
    })
      .then((response) => {
        if (!response.invite_result) {
          dispatch({ type: 'fail' });
        } else {
          const result = parseInviteUsersResponseInviteResult(
            response.invite_result,
          );
          dispatch({ type: 'receive_response', payload: result });
          setSubmissionStatus('submitted');
        }
        return;
      })
      .catch(() => {
        dispatch({ type: 'fail' });
        //TODO: log error
      });
  }, [
    submissionStatus,
    customMessage,
    dashInvitePlatform,
    individualContacts,
    inviteSource,
    canInviteAllTeamMembers,
    canAssignRoles,
    canInviteWithOptionalMessage,
    isValidForm,
    teamContacts,
  ]);

  if (submissionStatus === 'submitted') {
    return {
      handleSubmit,
      submissionStatus,
      submissionResult: state,
    };
  } else {
    return {
      handleSubmit,
      submissionStatus,
      submissionResult: null,
    };
  }
};
