import { Card } from '@dropbox/dig-components/card';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import {
  LinkTrackingMini,
  MagicSignatureMini,
} from '@dropbox/dig-illustrations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_View_DashSelfServeOnboarding } from '@mirage/analytics/events/types/view_dash_self_serve_onboarding';
import { useDoOnceOnMount } from '@mirage/shared/hooks/useDoOnceOnMount';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useOnboardingSurveys } from '../hooks/useOnboardingSurveys';
import { useSelfServeOnboardingState } from '../hooks/useSelfServeOnboardingState';
import styles from './OnboardingJobsToBeDoneStep.module.css';

type Props = {
  isAdmin: boolean;
  onValidationChange: (valid: boolean) => void;
  onProvisionDash: () => Promise<boolean>;
};

const ANSWERS_IMAGE =
  'https://assets.dropbox.com/images/dashweb/growth/onboarding/answers.svg';

const JTBD_OPTIONS = [
  {
    value: 'search',
    title: i18n.t('self_serve_jtbd_search_answer_title'),
    description: i18n.t('self_serve_jtbd_search_answer_description'),
    image: (
      <LinkTrackingMini
        width={128}
        altText={i18n.t('self_serve_jtbd_search_answer_title')}
      />
    ),
  },
  {
    value: 'answers',
    title: i18n.t('self_serve_jtbd_answers_answer_title'),
    description: i18n.t('self_serve_jtbd_answers_answer_description'),
    image: (
      <img
        src={ANSWERS_IMAGE}
        alt={i18n.t('self_serve_jtbd_answers_answer_title')}
      />
    ),
  },
  {
    value: 'ai_tools',
    title: i18n.t('self_serve_jtbd_ai_tools_answer_title'),
    description: i18n.t('self_serve_jtbd_ai_tools_answer_description'),
    image: (
      <MagicSignatureMini
        width={128}
        altText={i18n.t('self_serve_jtbd_ai_tools_answer_title')}
      />
    ),
  },
];

export const OnboardingJobsToBeDoneStep = forwardRef(
  ({ isAdmin, onValidationChange, onProvisionDash }: Props, ref) => {
    const { reportPapEvent } = useMirageAnalyticsContext();
    const { submitSurvey, surveyResponses } =
      useOnboardingSurveys('JOBS_TO_BE_DONE_V1');
    const { saveOnboardingState } = useSelfServeOnboardingState();
    const [internalValues, setInternalValues] = useState<string[]>([]);

    useImperativeHandle(ref, () => ({
      submitSurvey: () => handleSurveySubmit(),
    }));

    useDoOnceOnMount(() => {
      reportPapEvent(
        PAP_View_DashSelfServeOnboarding({
          selfServeIsTeamAdmin: isAdmin,
          onboardingStep: 'jtbd',
          featureLine: 'onboarding',
        }),
      );
    });

    const getSurveyAnswer = useCallback(() => {
      return [
        {
          surveyQuestionId: 'JOBS_TO_BE_DONE_V1',
          surveyAnswer: internalValues.join(','),
        },
      ];
    }, [internalValues]);

    useEffect(() => {
      onValidationChange(internalValues.length > 0);
    }, [internalValues, onValidationChange]);

    useEffect(() => {
      if (surveyResponses?.length) {
        const jtbdItems = surveyResponses[0].surveyAnswer?.split(',');
        if (jtbdItems) {
          setInternalValues(jtbdItems.filter((str) => str.trim() !== ''));
        }
      }
    }, [surveyResponses]);

    const handleSurveySubmit = useCallback(async () => {
      try {
        const surveyResponses = getSurveyAnswer();
        const success = await onProvisionDash();
        if (!success) return false;
        await saveOnboardingState();
        return submitSurvey(surveyResponses);
      } catch {
        return;
      }
    }, [getSurveyAnswer, onProvisionDash, saveOnboardingState, submitSurvey]);

    const onJobToBeDoneChange = useCallback((value: string) => {
      setInternalValues((prevValues: string[]) => {
        const isSelected = prevValues.includes(value);

        if (isSelected) {
          return prevValues.filter((val) => val !== value);
        }

        return [...prevValues, value];
      });
    }, []);

    return (
      <Box className={styles.jtbdContainer} display="flex" flexDirection="row">
        {JTBD_OPTIONS.map((option) => (
          <Card
            key={option.value}
            borderRadius="Large"
            className={classnames(styles.jtbdOption, {
              [styles.selected]: internalValues.includes(option.value),
            })}
            role="button"
            focusBehavior="focus"
            onClick={() => onJobToBeDoneChange(option.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                onJobToBeDoneChange(option.value);
              }
            }}
          >
            <Title size="medium" weightVariant="emphasized">
              {option.title}
            </Title>
            <Text
              className={styles.jtbdDescription}
              variant="label"
              size="medium"
            >
              {option.description}
            </Text>
            <Box
              className={styles.jtbdImgContainer}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {option.image}
            </Box>
          </Card>
        ))}
      </Box>
    );
  },
);

OnboardingJobsToBeDoneStep.displayName = 'OnboardingJobsToBeDoneStep';
