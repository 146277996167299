import { TextInput } from '@dropbox/dig-components/text_fields';
import { KeyCodes } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';
import { memo, useCallback, useState } from 'react';
import styles from './AnswerFollowUpInput.module.css';

import type { KeyboardEvent } from 'react';

interface AnswerFollowUpInputProps {
  onSubmit: (query: string) => void;
  waitingForResponse: boolean;
}
/**
 * Presentational logic for the follow up input box in the MultiAnswerCard
 */
export const AnswerFollowUpInput = memo(
  ({ onSubmit, waitingForResponse }: AnswerFollowUpInputProps) => {
    const [query, setQuery] = useState('');
    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === KeyCodes.enter && query) {
          onSubmit(query);
        }
      },
      [onSubmit, query],
    );
    return (
      <TextInput.Container
        className={styles.followUpInputContainer}
        size="large"
        isTransparent={false}
      >
        <TextInput.Input
          placeholder={i18n.t('answers_follow_up_placeholder')}
          onChange={(e) => setQuery(e.target.value)}
          value={query}
          onKeyDown={handleKeyDown}
          disabled={waitingForResponse}
        />
      </TextInput.Container>
    );
  },
);
AnswerFollowUpInput.displayName = 'AnswerFollowUpInput';
