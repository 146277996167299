import { Card, Metadata } from '@dropbox/dash-component-library';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import {
  getSourceRowParts,
  type SourceRowParts,
} from '@mirage/mosaics/Chat/components/compose-sources/ComposeSourceRow';
import { getAllReferencedSources } from '@mirage/mosaics/Chat/utils/messageSources';
import { openURL } from '@mirage/service-platform-actions';
import { getSourceUUID } from '@mirage/shared/compose/compose-session';
import i18n from '@mirage/translations';
import { memo, useState } from 'react';
import styles from './AnswerCitationCard.module.css';

import type {
  ChatConversationMessage,
  ChatSource,
} from '@mirage/mosaics/Chat/types';

const DEFAULT_NUM_CARDS = 4;

interface AnswerCitationCardsProps {
  messages: ChatConversationMessage[];
}
export const AnswerCitationCards = memo(
  ({ messages }: AnswerCitationCardsProps) => {
    const sources = getAllReferencedSources(messages);
    const sourcesArray = Array.from(sources.values());
    const [showAll, setShowAll] = useState(false);

    // show N-1 sources + "show more" if there are more than N
    const sourcesToShow =
      !showAll && sourcesArray.length > DEFAULT_NUM_CARDS
        ? sourcesArray.slice(0, DEFAULT_NUM_CARDS - 1)
        : sourcesArray;

    if (sourcesArray.length === 0) {
      return null;
    }
    return (
      <>
        {sourcesToShow.map((source) => (
          <AnswerCitationCard key={getSourceUUID(source)} source={source} />
        ))}
        {sourcesToShow.length < sourcesArray.length && (
          <AnswerCitationShowMoreCard
            moreSources={sourcesArray.slice(sourcesToShow.length)}
            onClick={() => setShowAll(true)}
          />
        )}
      </>
    );
  },
);
AnswerCitationCards.displayName = 'AnswerCitationCards';

interface AnswerCitationCardProps {
  source: ChatSource;
}
export const AnswerCitationCard = memo(
  ({ source }: AnswerCitationCardProps) => {
    const sourceRowParts = getSourceRowParts(source, {
      time: {
        abbreviateTime: true,
        abbreviateDate: true,
      },
      icon: {
        hasBackground: false,
        size: 'small',
      },
      subtitle: {
        hideServiceName: true,
      },
    });
    const handleClick = (e: React.MouseEvent | React.KeyboardEvent) => {
      e.preventDefault();
      if (sourceRowParts?.mirageResult?.url) {
        openURL(sourceRowParts.mirageResult.url);
      }
    };
    if (!sourceRowParts) {
      return null;
    }
    return (
      <Card variant="outline" isLink className={styles.AnswerCitationCard}>
        <Card.Contents breakout="large">
          <Card.OverlayLink
            onClick={handleClick}
            href={sourceRowParts.mirageResult.url ?? '#'}
          >
            <Text size="medium" className={styles.AnswerCitationCardTitle}>
              <Truncate lines={2}>{sourceRowParts.titleString}</Truncate>
            </Text>
          </Card.OverlayLink>
        </Card.Contents>
        <Card.Contents breakout="large">
          <Metadata
            size="small"
            withDividers="blank"
            className={styles.AnswerCitationCardMetadata}
          >
            <Metadata.Item>{sourceRowParts.icon}</Metadata.Item>
            <Metadata.Item>{sourceRowParts.subtitle}</Metadata.Item>
          </Metadata>
        </Card.Contents>
      </Card>
    );
  },
);
AnswerCitationCard.displayName = 'AnswerCitationCard';

interface AnswerCitationShowMoreCardProps {
  moreSources: ChatSource[];
  onClick: () => void;
}
export const AnswerCitationShowMoreCard = memo(
  ({ moreSources, onClick }: AnswerCitationShowMoreCardProps) => {
    const sourcesRowParts: SourceRowParts[] = [];
    for (const source of moreSources) {
      const sourceRowParts = getSourceRowParts(source, {
        icon: {
          hasBackground: false,
          size: 'small',
        },
      });
      if (sourceRowParts) {
        sourcesRowParts.push(sourceRowParts);
      }
    }
    return (
      <Card variant="outline" isLink className={styles.AnswerCitationCard}>
        <Card.Contents breakout="large">
          <Card.OverlayLink onClick={onClick} href={'#'}>
            <Text size="medium" className={styles.AnswerCitationCardTitle}>
              {i18n.t('answers_see_more_citations', {
                count: moreSources.length,
              })}
            </Text>
          </Card.OverlayLink>
        </Card.Contents>
        <Card.Contents breakout="large">
          <Metadata
            size="small"
            withDividers="blank"
            className={styles.AnswerCitationCardMetadata}
          >
            {sourcesRowParts.slice(0, 5).map((sourceRowParts, i) => (
              <Metadata.Item key={i}>{sourceRowParts.icon}</Metadata.Item>
            ))}
          </Metadata>
        </Card.Contents>
      </Card>
    );
  },
);
AnswerCitationShowMoreCard.displayName = 'AnswerCitationShowMoreCard';
