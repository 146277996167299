import { UIIcon } from '@dropbox/dig-icons';
import { SidebarHideLine } from '@dropbox/dig-icons/assets';
import { PAP_Click_CollapseButton } from '@mirage/analytics/events/types/click_collapse_button';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import i18n from '@mirage/translations';
import { memo, useCallback } from 'react';

import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type { ComponentType, SVGAttributes } from 'react';

interface ToggleExpandButtonProps {
  variant: 'expand' | 'collapse';
  setExpanded: (expanded: boolean) => void;
  disabled?: boolean;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
  icon?: ComponentType<SVGAttributes<SVGElement>>;
}
export const ToggleExpandButton = memo(
  ({
    variant,
    setExpanded,
    logComposeEvent,
    disabled,
    icon = SidebarHideLine,
  }: ToggleExpandButtonProps) => {
    const handleClickExpand = useCallback(() => {
      setExpanded(variant === 'expand' ? true : false);
      logComposeEvent(
        PAP_Click_CollapseButton({
          actionType: variant === 'expand' ? 'expand' : 'collapse',
        }),
      );
    }, [setExpanded, variant, logComposeEvent]);
    return (
      <IconButtonWithTooltip
        variant="borderless"
        tooltipProps={{
          title: variant === 'expand' ? i18n.t('expand') : i18n.t('collapse'),
        }}
        onClick={handleClickExpand}
        disabled={disabled}
      >
        <UIIcon src={icon} />
      </IconButtonWithTooltip>
    );
  },
);
ToggleExpandButton.displayName = 'ToggleExpandButton';
