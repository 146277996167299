import { IconButton } from '@dropbox/dig-components/buttons';
import { ThemeContainer, ThemeProvider } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { ListViewLine } from '@dropbox/dig-icons/assets';
import { DashWordmark, WordmarkLogo } from '@dropbox/dig-logos';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { MobileDownloadBanner } from '@mirage/growth/components/MobileDownloadBanner';
import { BetaBadge } from '@mirage/shared/badges/BetaBadge';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import { activeStackAtom } from '@mirage/stacks/ActiveStack/atoms';
import { useCurrentBackgroundTheme } from '@mirage/stacks/themes';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { globalNavIsCollapsedAtom } from '../GlobalNav/atoms';
import { useSharedOmniboxProps } from '../Omnibox/hooks/useSharedOmniboxProps';
import { PersistedOmnibox } from '../Omnibox/withPersistedOmniboxSurface';
import styles from './TopNav.module.css';

type TopNavProps = {
  isAugustRevisionEnabled: boolean;
  isDesktopExperience: boolean;
  isMobileExperience: boolean;
  isAIOmniboxEnabled: boolean;
};

export const TopNav = ({
  isAugustRevisionEnabled,
  isDesktopExperience,
  isMobileExperience,
  isAIOmniboxEnabled,
}: TopNavProps) => {
  const navigate = useNavigate();
  const { pageScrolled } = useSharedOmniboxProps();

  const stackFromFullStackPage = useAtomValue(activeStackAtom);
  const backgroundTheme = useCurrentBackgroundTheme(stackFromFullStackPage);
  const isAugustStackPageEnabled = useStackPageAugustRevisionEnabled();

  const [isCollapsed, setIsCollapsed] = useAtom(globalNavIsCollapsedAtom);

  if (isDesktopExperience) {
    return (
      <ThemeProvider overrides={backgroundTheme}>
        <ThemeContainer>
          <PersistedOmnibox />
        </ThemeContainer>
      </ThemeProvider>
    );
  }

  if (isMobileExperience) {
    return (
      <ThemeProvider overrides={backgroundTheme}>
        <ThemeContainer>
          <div className={classNames(styles.container)}>
            <MobileDownloadBanner />
            <div
              className={classNames(styles.navContainer, {
                [styles.mobile]: true,
                [styles.augustRevision]: isAugustRevisionEnabled,
                [styles.showBackground]:
                  isAugustStackPageEnabled && backgroundTheme !== undefined,
                [styles.scrollingBorder]: pageScrolled && !isDesktopExperience,
              })}
            >
              <div className={styles.leftContainer}>
                <IconButton
                  className={styles.noShrink}
                  shape="circular"
                  variant="transparent"
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  aria-label={i18n.t(
                    isCollapsed ? 'expand_nav_aria' : 'collapse_nav_aria',
                  )}
                  aria-expanded={!isCollapsed}
                >
                  <UIIcon src={ListViewLine} />
                </IconButton>
              </div>
              <>
                <div
                  tabIndex={0}
                  className={styles.centerContainer}
                  role="button"
                  aria-label={i18n.t('home')}
                  onClick={() => navigate('/')}
                  onKeyDown={onKeyDownCommitFn(() => navigate('/'))}
                >
                  <WordmarkLogo
                    size={32}
                    src={DashWordmark}
                    color="var(--dig-color__text__base)"
                  />
                  <BetaBadge excludeDfb />
                </div>
                <div className={styles.mobileSearchBar}>
                  <PersistedOmnibox />
                </div>
              </>
            </div>
          </div>
        </ThemeContainer>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider overrides={backgroundTheme}>
      <ThemeContainer>
        <div className={classNames(styles.container)}>
          <div
            className={classNames(styles.navContainer, {
              [styles.mobile]: isMobileExperience,
              [styles.augustRevision]: isAugustRevisionEnabled,
              [styles.showBackground]:
                isAugustStackPageEnabled && backgroundTheme !== undefined,
              [styles.scrollingBorder]: pageScrolled && !isDesktopExperience,
              [styles.aiOmnibox]: isAIOmniboxEnabled,
            })}
          >
            <div className={styles.leftContainer}></div>
            <PersistedOmnibox />
          </div>
        </div>
      </ThemeContainer>
    </ThemeProvider>
  );
};
