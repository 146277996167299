import { Button } from '@dropbox/dig-components/buttons';
import { FormLabel, FormRow } from '@dropbox/dig-components/form_row';
import { TextArea } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine } from '@dropbox/dig-icons/assets';
import i18n from '@mirage/translations';
import { type FC, memo, useCallback, useState } from 'react';
import { type ManagedInviteFormPropsWithInitialData } from './hooks/useManagedInviteForm';
import { InviteEntireTeam } from './InviteEntireTeam';
import styles from './InviteForm.module.css';
import { InviteFormRow } from './InviteFormRow';

import type { IndividualInviteFormContact, InviteFormContact } from './types';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface InviteFormProps
  extends ManagedInviteFormPropsWithInitialData {}

export const InviteForm: FC<InviteFormProps> = memo(
  (managedInviteFormProps) => {
    const [isTextAreaFocused, setIsTextAreaFocused] = useState(false);
    const [hasInvitedEntireTeam, setHasInvitedEntireTeam] = useState(false);

    const {
      individualContacts,
      validIndividualContactCount,
      validTeamContactCount,
      initialMessage,
      disabled,
      setTeamContacts,
      clearTeamContacts,
      addEmptyIndividualContact,
      updateIndividualContact,
      removeIndividualContact,
      updateMessage,
      initialInviteFormData: {
        teamMembers,
        licenseCount,
        licensesUsed,
        canInviteAllTeamMembers,
        canAssignRoles,
        canInviteWithOptionalMessage,
      },
    } = managedInviteFormProps;
    const licenseToBeUsed =
      licensesUsed + validIndividualContactCount + validTeamContactCount;
    const licenseToBeUsedIfInviteEntireTeam =
      licensesUsed + validIndividualContactCount + teamMembers.length;
    /**
     * If over-quota after invite entire team checked, do not disable and allow
     *   user to fix it by unchecking
     */
    const insufficientLicenses =
      licenseToBeUsedIfInviteEntireTeam > licenseCount && !hasInvitedEntireTeam;

    const handleMessageChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        updateMessage(e.currentTarget.value);
      },
      [updateMessage],
    );

    const handleAddRow = useCallback(() => {
      addEmptyIndividualContact();
    }, [addEmptyIndividualContact]);

    const handleInviteFormRowChange = useCallback(
      (contactId, contact: InviteFormContact) => {
        // TODO: Validate email
        updateIndividualContact({
          contactId,
          ...contact,
        });
      },
      [updateIndividualContact],
    );

    const handleRemoveRow = useCallback(
      (contactId: number) => {
        removeIndividualContact(contactId);
      },
      [removeIndividualContact],
    );

    const handleToggleInviteAllCheckbox = useCallback(
      (checked: boolean) => {
        if (!teamMembers) {
          return;
        }
        if (checked) {
          setTeamContacts(
            teamMembers.map((member) => ({
              email: member.email,
              newRole: null,
              validationError: null,
            })),
          );
        } else {
          clearTeamContacts();
        }
        setHasInvitedEntireTeam(checked);
      },
      [setTeamContacts, clearTeamContacts, teamMembers],
    );

    const getShouldDisableRoleSelect = useCallback(
      (contact: Omit<IndividualInviteFormContact, 'contactId'>) => {
        const hasEmail = !!contact?.email;
        const hasError = !!contact?.validationError;
        const isTeamMember =
          teamMembers.findIndex((t) => t.email === contact.email) !== -1;
        return !hasEmail || hasError || isTeamMember;
      },
      [teamMembers],
    );

    const handleTextAreaFocus = useCallback(() => {
      setIsTextAreaFocused(true);
    }, []);
    const handleTextAreaBlur = useCallback(() => {
      setIsTextAreaFocused(false);
    }, []);

    return (
      <Box className={styles.inviteFormBody}>
        {canInviteAllTeamMembers && (
          <InviteEntireTeam
            teamMembers={teamMembers}
            disabled={disabled || insufficientLicenses}
            insufficientLicensesTooltipTitle={
              insufficientLicenses
                ? i18n.t('remove_emails_until_you_have_enough_licenses')
                : undefined
            }
            onCheckboxToggle={handleToggleInviteAllCheckbox}
          />
        )}

        <Text variant="label" size="large" tagName="p" isBold>
          {i18n.t('team_invite_add_via_email')}
        </Text>

        {individualContacts.map(({ contactId, ...contact }) => (
          <InviteFormRow
            className={styles.inviteFormRow}
            key={`invite-form-row-${contactId}`}
            showRoleSelect={canAssignRoles}
            disableRoleSelect={getShouldDisableRoleSelect(contact)}
            teamMembers={teamMembers}
            onChange={(contact) =>
              handleInviteFormRowChange(contactId, contact)
            }
            onClose={() => handleRemoveRow(contactId)}
            preventClose={individualContacts.length === 1}
            emailValidationError={contact.validationError || undefined}
            disabled={disabled}
          />
        ))}

        <Box display="flex" flexDirection="row" alignItems="center">
          <Button
            variant="borderless"
            disabled={disabled || licenseToBeUsed >= licenseCount}
            onClick={handleAddRow}
            withIconStart={<UIIcon src={AddLine} />}
            className={styles.addTypeaheadButton}
          >
            {i18n.t('team_invite_add_another')}
          </Button>
          <Box flexGrow={1}></Box>
          <Text size="xsmall" className={styles.availableLicensesLabel}>
            {i18n.t('team_invite_of_x_licenses', {
              licensesUsed: licenseToBeUsed,
              licenseCount,
            })}
          </Text>
        </Box>

        {canInviteWithOptionalMessage && (
          <FormRow>
            <FormLabel
              htmlFor="invite-modal-optional-message"
              className={styles.optionalMessageLabel}
            >
              {i18n.t('optional_message')}
            </FormLabel>
            <TextArea
              id="invite-modal-optional-message"
              data-testid="optional-message-textarea"
              onChange={handleMessageChange}
              defaultValue={initialMessage}
              onFocus={handleTextAreaFocus}
              onBlur={handleTextAreaBlur}
              placeholder={
                isTextAreaFocused ? '' : i18n.t('optional_message_placeholder')
              }
              size="medium"
              className={styles.optionalMessageTextArea}
              disabled={disabled}
            />
          </FormRow>
        )}
      </Box>
    );
  },
);

InviteForm.displayName = 'InviteForm';
