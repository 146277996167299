import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Box } from '@dropbox/dig-foundations';
import { PAP_View_DashSelfServeOnboarding } from '@mirage/analytics/events/types/view_dash_self_serve_onboarding';
import { useManagedInviteFormWithInitialData } from '@mirage/growth/team-invites/hooks/useManagedInviteForm';
import { InviteForm } from '@mirage/growth/team-invites/InviteForm';
import { InviteModalSubmitPartialSuccess } from '@mirage/growth/team-invites/InviteModalPostSubmit';
import {
  DashInviteFormSource,
  InviteFormStatus,
} from '@mirage/growth/team-invites/types';
import { reportPapEvent } from '@mirage/service-product-logging';
import { useDoOnceOnMount } from '@mirage/shared/hooks/useDoOnceOnMount';
import i18n from '@mirage/translations';
import { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import styles from './OnboardingTeamInviteStep.module.css';

type Props = {
  isAdmin: boolean;
  onValidationChange: (valid: boolean) => void;
  onContinue: () => void;
};

const OnboardingTeamInviteStep = forwardRef(
  ({ isAdmin, onValidationChange, onContinue }: Props, ref) => {
    const handleFormValidationChange = (valid: boolean) => {
      onValidationChange(valid);
    };
    const {
      inviteFormStatus,
      managedInviteFormProps,
      handleSubmit,
      submissionResult,
    } = useManagedInviteFormWithInitialData({
      initialMessage: i18n.t('optional_message_default'),
      initialEmptyInputCount: 4,
      dashInviteFormSource: DashInviteFormSource.DASH_NEW_TEAM_ONBOARDING,
      onFormValidationChange: handleFormValidationChange,
    });

    useDoOnceOnMount(() => {
      reportPapEvent(
        PAP_View_DashSelfServeOnboarding({
          selfServeIsTeamAdmin: isAdmin,
          onboardingStep: 'team_invite',
          featureLine: 'onboarding',
        }),
      );
    });

    useEffect(() => {
      if (
        inviteFormStatus === InviteFormStatus.SUBMITTED &&
        !submissionResult?.failedEmails?.length
      ) {
        // If the invite form has been submitted and there are no failures, continue to the next step
        onContinue();
      }
    }, [inviteFormStatus, submissionResult, onContinue]);

    const handlePartialSuccessModalClose = useCallback(() => {
      onContinue();
    }, [onContinue]);

    const handleSubmitSurvey = useCallback(() => {
      if (!handleSubmit) return;
      handleSubmit();
      return false;
    }, [handleSubmit]);

    useImperativeHandle(ref, () => ({
      submitSurvey: () => handleSubmitSurvey(),
    }));

    if (inviteFormStatus === InviteFormStatus.LOADING) {
      return (
        <Box
          className={styles.loadingContainer}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner aria-valuetext={i18n.t('loading')} size="large" />
        </Box>
      );
    }

    return managedInviteFormProps ? (
      <>
        <InviteForm {...managedInviteFormProps} />
        {inviteFormStatus === InviteFormStatus.SUBMITTED &&
          submissionResult?.failedEmails?.length && (
            <InviteModalSubmitPartialSuccess
              successfullyInvitedEmails={
                submissionResult.successfullyInvitedEmails
              }
              successfullySuggestedEmails={
                submissionResult.successfullySuggestedEmails
              }
              failedEmails={submissionResult.failedEmails}
              onClose={handlePartialSuccessModalClose}
            />
          )}
      </>
    ) : null;
  },
);

OnboardingTeamInviteStep.displayName = 'OnboardingTeamInviteStep';
export default OnboardingTeamInviteStep;
