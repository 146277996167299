import { Chip } from '@dropbox/dig-components/chip';
import { UIIcon } from '@dropbox/dig-icons';
import React from 'react';

import type { OmniboxScopedMode } from '@mirage/mosaics/Omnibox/types';
import type { SVGProps } from 'react';

export type ScopedModeChipProps = {
  src: (props: SVGProps<SVGSVGElement>) => React.ReactElement;
  modeType: OmniboxScopedMode;
  chipLabel: string;
  onClickHandler: (modeType: OmniboxScopedMode) => void;
  disabled?: boolean;
};

export const ScopedModeChip = ({
  src,
  chipLabel,
  onClickHandler,
  modeType,
  disabled = false,
}: ScopedModeChipProps) => {
  return (
    // TODO - fix chip sizing. Dig's chip only has medium + small size, medium is a bit smaller than the actual design
    // asked: https://www.figma.com/design/G29iQ9pJGE73MCNSuRd7JM?node-id=2085-299556&m=dev#1181229550
    <Chip
      onClick={() => onClickHandler(modeType)}
      size="medium"
      disabled={disabled}
    >
      <Chip.IconAccessory>
        <UIIcon src={src} />
      </Chip.IconAccessory>
      <Chip.Content>{chipLabel}</Chip.Content>
    </Chip>
  );
};
