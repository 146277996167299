import { useMagicFlags } from '@mirage/mosaics/MagicFlags/useMagicFlags';
import * as dbxApiService from '@mirage/service-dbx-api';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { PERFORMANCE_MARKS } from '@mirage/service-operational-metrics/performance-constants';
import { useCallback, useEffect, useRef, useState } from 'react';
import { from, of, Subject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import type { MultiAnswerResponse } from '@mirage/shared/answers/multi-answer';
import type { Subscription } from 'rxjs';

const useMultiAnswers = (markAnswersCompleted?: () => void) => {
  const [answers, setAnswers] = useState<MultiAnswerResponse | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(false);
  const querySubject = useRef(new Subject<string>()).current;

  const { list: magicFlagsList, string: magicFlagsString } = useMagicFlags();

  const experimentSetting = useFeatureFlagValue(
    'context_engine_2024_08_19_experiment_setting',
  );

  const isStreamingEnabled = useFeatureFlagValue(
    'context_engine_2025_02_10_streaming_answers',
  );

  // If the query does not have specific magic flags, add them here from the user settings
  const addMagicFlags = useCallback(
    (query: string) => {
      if (magicFlagsList.length > 0) {
        query += ' #answers_experimental_flags=' + magicFlagsString;
      }
      return query;
    },
    [magicFlagsString, magicFlagsList.length],
  );

  useEffect(() => {
    let subscription: Subscription;

    if (!isStreamingEnabled) {
      subscription = querySubject
        .pipe(
          switchMap((query) => {
            setLoading(true);
            query = addMagicFlags(query);
            return from(dbxApiService.fetchAnswersForQuery(query)).pipe(
              catchError(() => of(undefined)),
            );
          }),
        )
        .subscribe({
          next: (response) => {
            setAnswers(response);
            setLoading(false);
            markAnswersCompleted?.();
          },
          error: () => {
            setLoading(false);
            markAnswersCompleted?.();
          },
          complete: () => {
            setLoading(false);
          },
        });
    } else {
      subscription = querySubject
        .pipe(
          switchMap((query) => {
            setLoading(true);
            setAnswers(undefined);
            query = addMagicFlags(query);
            return dbxApiService.grpc.genAnswersForQuery(
              query,
              undefined,
              String(experimentSetting),
            );
          }),
          catchError(() => {
            markAnswersCompleted?.();
            return of(undefined);
          }),
        )
        .subscribe({
          next: (resp) => {
            if (resp?.error) {
              setAnswers(resp?.response);
              setLoading(false);
              // No answers from the server is called an error, so we need to mark it as completed
              markAnswersCompleted?.();
              return;
            }
            // Check if at least one answer is present with answer.
            if (
              resp?.response?.answers?.some(
                (answer) => answer?.answer?.length > 0,
              )
            ) {
              setAnswers(resp?.response);
              setLoading(false);
              markAnswersCompleted?.();
            }
          },
          error: () => {
            setLoading(false);
            setAnswers({
              answers: [],
              requestId: 'error',
            });
            markAnswersCompleted?.();
          },
          complete: () => {
            setLoading(false);
          },
        });
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [
    querySubject,
    magicFlagsString,
    magicFlagsList.length,
    isStreamingEnabled,
    experimentSetting,
    addMagicFlags,
    markAnswersCompleted,
  ]);

  const fetch = useCallback(
    (query: string) => {
      // Mark the start of our fetch for perf tracking
      performance.mark(PERFORMANCE_MARKS.MULTI_ANSWERS.FETCH_START);
      setAnswers(undefined);
      querySubject.next(query);
    },
    [querySubject],
  );

  const reset = useCallback(() => {
    setAnswers(undefined);
    setLoading(false);
  }, []);

  return {
    answers,
    loading,
    fetch,
    reset,
    isStreamingEnabled: Boolean(isStreamingEnabled),
  };
};

export default useMultiAnswers;
