import { Split } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Scroll_ActivityFeed } from '@mirage/analytics/events/types/scroll_activity_feed';
import { PAP_Shown_ActivityFeed } from '@mirage/analytics/events/types/shown_activity_feed';
import { PAP_Shown_ActivityFeedItem } from '@mirage/analytics/events/types/shown_activity_feed_item';
import {
  useFeatureFlagValue,
  useFeatureFlagValueBool,
} from '@mirage/service-experimentation/useFeatureFlagValue';
import { refreshActivityFeed } from '@mirage/service-feed';
import { useActivityFeed } from '@mirage/service-feed/hooks/useActivityFeed';
import { activityDerivePAPProps } from '@mirage/service-feed/util/analytics';
import { privacyModeEnabledAtom } from '@mirage/shared/atoms/privacyMode';
import { Blur } from '@mirage/shared/blur/Blur';
import { useDebounce } from '@mirage/shared/hooks/useDebounce';
import { ModuleHeader } from '@mirage/shared/two-column-grid/ModuleHeader';
import i18n from '@mirage/translations';
import { useMountEffect } from '@react-hookz/web';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { ActivityList } from '../ActivityList/ActivityList';
import { UserActivityFeedFilters } from '../UserActivityFeedFilters/UserActivityFeedFilters';

import type { dash_feed } from '@dropbox/api-v2-client';
import type { ActivityFeedFilters } from '@mirage/service-feed/types';

const useReportFeedScroll = () => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  const handleScroll = useCallback(
    (activity: dash_feed.FeedItem, position?: number) => {
      reportPapEvent(
        PAP_Scroll_ActivityFeed({
          actionSurface: 'activity_feed',
          featureLine: 'activity_feed',
          scrollDepth: position,
        }),
      );
    },
    [reportPapEvent],
  );

  return useDebounce(handleScroll, 2000);
};

const useRefreshActivityFeed = () => {
  // currently we are refreshing the activity feed every time the page loads, and not incrementally
  useMountEffect(() => {
    refreshActivityFeed();
  });
};

const useReportShownDashStartPage = () => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  useMountEffect(() => {
    reportPapEvent(
      PAP_Shown_ActivityFeed({
        actionSurfaceComponent: 'activity_feed',
        featureLine: 'activity_feed',
      }),
    );
  });
};

const useReportShownActivityFeedItem = () => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  return useCallback(
    (activity: dash_feed.FeedItem, position: number) => {
      reportPapEvent(
        PAP_Shown_ActivityFeedItem({
          actionSurfaceComponent: 'activity_feed',
          featureLine: 'activity_feed',
          ...activityDerivePAPProps(activity, position),
        }),
      );
    },
    [reportPapEvent],
  );
};

// we check this feature flag in the feed service to determine which version of the activity feed api to call
// however we can't do exposure logging in a service, so we check it again here to log the exposure
const useLogActivityFeedV2Exposure = () => {
  useFeatureFlagValue(
    'dash_2024_12_23_activity_feed_use_list_feed_items',
    true,
  );
};

export const UserActivityFeedModule = () => {
  useRefreshActivityFeed();
  useLogActivityFeedV2Exposure();
  useReportShownDashStartPage();

  const reportFeedScroll = useReportFeedScroll();
  const reportFeedItemShown = useReportShownActivityFeedItem();
  const privacyModeEnabled = useAtomValue(privacyModeEnabledAtom);

  const isStacksPageTabFeatureEnabled = useFeatureFlagValueBool(
    'dash_web_2025_03_27_start_page_tabs',
  );

  const {
    filters,
    setFilters,
    clearFilters,
    activities,
    isRefreshing,
    loadMore,
    hasMore,
    isLoadingMore,
  } = useActivityFeed();

  const showLoadingPlaceholders =
    (isRefreshing && !activities) || isLoadingMore;

  return (
    <Split direction="vertical" gap="8">
      {!isStacksPageTabFeatureEnabled && (
        <Split.Item>
          <UserActivityFeedHeader filters={filters} setFilters={setFilters} />
        </Split.Item>
      )}

      <Split.Item>
        <Blur isBlurred={privacyModeEnabled} blurAmount="high">
          <ActivityList
            showLoadingPlaceholders={showLoadingPlaceholders}
            activities={activities || []}
            hasMore={!!hasMore}
            onReachEnd={loadMore}
            filters={filters}
            clearFilters={clearFilters}
            onViewItem={reportFeedItemShown}
            onFeedScroll={reportFeedScroll}
          />
        </Blur>
      </Split.Item>
    </Split>
  );
};

type UserActivityFeedHeaderProps = {
  filters: ActivityFeedFilters;
  setFilters: (filters: ActivityFeedFilters) => void;
};

const UserActivityFeedHeader = ({
  filters,
  setFilters,
}: UserActivityFeedHeaderProps) => {
  return (
    <ModuleHeader
      title={i18n.t('activity_feed_header_title')}
      actions={
        <UserActivityFeedFilters filters={filters} setFilters={setFilters} />
      }
    />
  );
};
