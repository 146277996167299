import './variables.module.css';

import { Box } from '@dropbox/dig-foundations';
import { ScopedButtonRow } from '@mirage/mosaics/Omnibox/components/Omnibar/ScopedButtonRow/ScopedButtonRow';
import cx from 'classnames';
import React from 'react';
import styles from './Omnibar.module.css';

import type { OmniboxScopedMode } from '@mirage/mosaics/Omnibox/types';

export type OmnibarProps = {
  // event handler for when we click the (+) sign to attach sources
  onAttachButtonClicked: () => void;
  // event handler for any of the scoped action buttons being clicked (find, ask, organize, create)
  onScopedButtonClicked: (m: OmniboxScopedMode) => void;
  // whether the OmniboxResults (typeahed overlay) is open or not
  isResultsOpen: boolean;
  // input row content
  children: React.ReactNode;
  // when true, omnibar will only render the omnibar. It will not render sources or scoped buttons
  isSlimMode?: boolean;
};

export const Omnibar = ({
  onAttachButtonClicked,
  onScopedButtonClicked,
  isResultsOpen,
  children,
  isSlimMode = false,
}: OmnibarProps) => {
  if (isSlimMode) {
    return (
      <Box className={cx(styles.omnibarContainer, styles.isSlimMode)}>
        {children}
      </Box>
    );
  }

  return (
    <Box
      className={cx(styles.omnibarContainer, {
        [styles.extendBottom]: isResultsOpen,
      })}
    >
      {/* TODO: OSE-5086 */}

      {/* Render back chevron + text input for query */}
      <Box paddingTop={'Micro Medium'} paddingBottom={'Micro Medium'}>
        {children}
      </Box>

      <ScopedButtonRow
        isResultsOpen={isResultsOpen}
        onAttachButtonClicked={onAttachButtonClicked}
        onScopedButtonClicked={onScopedButtonClicked}
      />
    </Box>
  );
};
Omnibar.displayName = 'Omnibar';
