import { Text } from '@dropbox/dig-components/typography';
import { Box, Split, Stack } from '@dropbox/dig-foundations';
import { PersonMetadata } from '@mirage/feed/components/ActivityListItem/ExpandedActivityListItem/PersonMetadata';
import { SearchResultIcon } from '@mirage/shared/icons';
import i18n from '@mirage/translations';
import { format } from 'date-fns';
import styles from './MultimediaSearchResults.module.css';

import type { SearchResult } from '@mirage/shared/search/search-result';

export const MediaResultExtendedMetadata = ({
  result,
  hideTitle,
}: {
  result: SearchResult;
  hideTitle?: boolean;
}) => {
  return (
    <>
      {!hideTitle && (
        <div className={styles.detailsHeader}>
          <Text size="large" isBold>
            {i18n.t('details')}
          </Text>
        </div>
      )}
      <Stack gap="12px">
        <MetadataEntry
          label="Source"
          value={
            <Split gap="8px">
              <Split.Item>
                <SearchResultIcon size={20} result={result} />
              </Split.Item>
              <Split.Item>
                <span>{result.connectorInfo.displayName}</span>
              </Split.Item>
            </Split>
          }
        />
        {result.fileTypeInfo?.displayName ? (
          <MetadataEntry
            label="Type"
            value={result.fileTypeInfo?.displayName}
          />
        ) : null}
        {result.lastModifier?.displayName ? (
          <>
            <MetadataEntry
              label="Updated by"
              value={<PersonMetadata person={result.lastModifier} />}
            />
            {result.updatedAtMs && (
              <MetadataEntry
                label="Last updated"
                value={format(
                  new Date(result.updatedAtMs),
                  'M/dd/yyyy hh:mm a',
                )}
              />
            )}
          </>
        ) : null}
        {result.creator?.displayName ? (
          <>
            <MetadataEntry
              label="Updated by"
              value={<PersonMetadata person={result.creator} />}
            />
            {result.providerCreatedAtMs && (
              <MetadataEntry
                label="Last updated"
                value={format(
                  new Date(result.providerCreatedAtMs),
                  'M/dd/yyyy hh:mm a',
                )}
              />
            )}
          </>
        ) : null}
        {result.media?.xPixels && result.media?.yPixels && (
          <MetadataEntry
            label="Dimensions"
            value={`${result.media.xPixels} x ${result.media.yPixels}`}
          />
        )}
        {result.media?.size ? (
          <MetadataEntry
            label="Size"
            value={`${Math.round(result.media.size / 1024)} KB`}
          />
        ) : null}
      </Stack>
    </>
  );
};

const MetadataEntry = (props: { label: string; value: React.ReactNode }) => {
  return (
    <Box width="100%" className={styles.metadataEntry}>
      <Split width="100%">
        <Split.Item width="fill">
          <Text variant="label" color="subtle">
            {props.label}
          </Text>
        </Split.Item>
        <Split.Item width="fill">
          <Text>{props.value}</Text>
        </Split.Item>
      </Split>
    </Box>
  );
};
