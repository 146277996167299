import { useFeatureFlagValueBool } from '@mirage/service-experimentation/useFeatureFlagValue';
import useSettings from '@mirage/service-settings/useSettings';
import { atom, useAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';

import type { TabIds } from '@mirage/service-settings/service/types';

const startPageSelectedTabAtom = atom<TabIds>('recents');

export const useSelectedTab = () => {
  const { settings, set: setSettings } = useSettings(['startPageSelectedTab']);
  const { startPageSelectedTab: startPageSelectedTabFromSettings } =
    settings ?? {};

  const isStacksPageTabFeatureEnabled = useFeatureFlagValueBool(
    'dash_web_2025_03_27_start_page_tabs',
  );
  const DEFAULT_FIRST_TAB = isStacksPageTabFeatureEnabled
    ? 'activity'
    : 'recents';

  useHydrateAtoms([
    [
      startPageSelectedTabAtom,
      startPageSelectedTabFromSettings || DEFAULT_FIRST_TAB,
    ],
  ]);

  const [startPageSelectedTab, _setStartPageSelectedTab] = useAtom(
    startPageSelectedTabAtom,
  );

  const setStartPageSelectedTab = (tabId: TabIds) => {
    _setStartPageSelectedTab(tabId);
    setSettings('startPageSelectedTab', tabId);
  };

  return {
    startPageSelectedTab,
    setStartPageSelectedTab,
  };
};
