import { Box } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Shown_TabPanel } from '@mirage/analytics/events/types/shown_tab_panel';
import { UserActivityFeedFilters } from '@mirage/feed/components/UserActivityFeedFilters/UserActivityFeedFilters';
import { UserActivityFeedModule } from '@mirage/feed/components/UserActivityFeedModule/UserActivityFeedModule';
import { useActivityFeed } from '@mirage/service-feed/hooks/useActivityFeed';
import { privacyModeEnabledAtom } from '@mirage/shared/atoms/privacyMode';
import { Blur } from '@mirage/shared/blur/Blur';
import { usePrevious } from '@mirage/shared/util/hooks';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';

export const ActivityTabPanel = ({
  headerButtonSlot,
  isActive,
}: {
  isActive: boolean;
  headerButtonSlot: Element | null;
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const privacyModeEnabled = useAtomValue(privacyModeEnabledAtom);
  const { filters, setFilters, activities } = useActivityFeed();

  const previousIsActive = usePrevious(isActive);
  useEffect(() => {
    // Let's only log when we go from not active -> active
    if (!previousIsActive && isActive) {
      reportPapEvent(
        PAP_Shown_TabPanel({
          startPageAction: 'click_activity_tab',
          isEmpty: activities?.length === 0,
          featureLine: 'activity_feed',
          actionSurfaceComponent: 'tab_panel',
        }),
      );
    }
  }, [isActive, reportPapEvent]);

  const withAccessory: React.ReactNode[] = [
    <UserActivityFeedFilters
      key="user-activity-feed-filters"
      filters={filters}
      setFilters={setFilters}
    />,
  ];

  return (
    <Box
      style={{
        display: isActive ? 'block' : 'none',
        position: 'relative',
        overflowY: privacyModeEnabled ? 'unset' : 'hidden', // fixes double-scroll bug on safari
      }}
    >
      {headerButtonSlot && createPortal(withAccessory, headerButtonSlot)}
      <Blur isBlurred={privacyModeEnabled} blurAmount="high">
        <UserActivityFeedModule />
      </Blur>
    </Box>
  );
};
