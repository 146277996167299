import { Text, Title } from '@dropbox/dig-components/typography';
import { BetaBadge } from '@mirage/shared/badges/BetaBadge';
import i18n from '@mirage/translations';
import { memo } from 'react';
import { useComposeCurrentSessionContext } from '../../data/current-session/ComposeCurrentSessionContext';
import styles from './SidePanelContainer.module.css';

export interface SidePanelContainerProps {
  leftTitleActions?: React.ReactNode;
  rightTitleActions?: React.ReactNode;
  children: React.ReactNode;
  handleClickAdd?: () => void;
}
export const SidePanelContainer = memo(
  ({
    leftTitleActions,
    rightTitleActions,
    children,
    handleClickAdd,
  }: SidePanelContainerProps) => {
    const { sources, showWorkflowAgent } = useComposeCurrentSessionContext();
    return (
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <div className={styles.titleActionsWrapper}>
            {leftTitleActions && (
              <div className={styles.titleActions}>{leftTitleActions}</div>
            )}
            <div className={styles.titleAndChip}>
              <Title size="small" className={styles.title}>
                {showWorkflowAgent
                  ? i18n.t('assistant_card_title_create_ai_tool')
                  : i18n.t('compose_assistant_title')}
              </Title>
              <div className={styles.titleChip}>
                <BetaBadge />
              </div>
              {sources.length > 0 && (
                <>
                  <div className={styles.sourceCountSpacer} />
                  <Text
                    size="medium"
                    onClick={handleClickAdd}
                    className={styles.sourceCount}
                  >
                    {/* TODO: This is not the right way to pluralize i18n strings*/}
                    {i18n.t('compose_session_number_of_sources', {
                      count: sources.length,
                      plural: sources.length > 1 ? 's' : '',
                    })}
                  </Text>
                </>
              )}
            </div>
          </div>
          {rightTitleActions && (
            <div className={styles.titleActionsWrapper}>
              <div className={styles.titleActions}>{rightTitleActions}</div>
            </div>
          )}
        </div>
        {children}
      </div>
    );
  },
);
SidePanelContainer.displayName = 'SidePanelContainer';
