import { Button } from '@dropbox/dig-components/buttons';
import { Snackbar } from '@dropbox/dig-components/snackbar';
import { UIIcon } from '@dropbox/dig-icons';
import { FailLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { PAP_Click_FormatButton } from '@mirage/analytics/events/types/click_format_button';
import { PAP_Click_TemplateMenuButton } from '@mirage/analytics/events/types/click_template_menu_button';
import { PAP_Click_VoiceDeleteButton } from '@mirage/analytics/events/types/click_voice_delete_button';
import { PAP_Click_VoiceMenuOption } from '@mirage/analytics/events/types/click_voice_menu_option';
import { ComposeEditor } from '@mirage/mosaics/ComposeAssistant/components/editor/ComposeEditor';
import { VoiceSettingsModal } from '@mirage/mosaics/ComposeAssistant/components/settings/VoiceSettingsModal';
import { useAssistTemplatesContext } from '@mirage/mosaics/ComposeAssistant/data/AssistTemplatesContext';
import { useComposeAnalyticsContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeAnalyticsContext';
import { useComposeVoicesContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeVoicesContext';
import {
  getSelectionActionPromptString,
  getSelectionPromptString,
} from '@mirage/mosaics/ComposeAssistant/data/llm/llm-prompts';
import {
  DEFAULT_PRECONFIGURED_VOICE_ID,
  getFirstMarkdownArtifact,
} from '@mirage/shared/compose/compose-session';
import i18n from '@mirage/translations';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { TemplateSettingsModal } from '../components/settings/TemplateSettingsModal';

import type { PostUserMessageParams } from '../data/current-session/ComposeCurrentSessionPostMessage';
import type { SelectionAction } from '@mirage/mosaics/ComposeAssistant/components/editor/SelectionToolbar';
import type { ComposeAssistantConversationMessage } from '@mirage/shared/compose/assist-message';
import type { AssistCustomTemplate } from '@mirage/shared/compose/assist-template';
import type {
  ComposeArtifact,
  ComposeAssistantDraftConfig,
  InputContext,
} from '@mirage/shared/compose/compose-session';
import type { ComposeVoice } from '@mirage/shared/compose/compose-voice';

interface VoiceSettingsModalConfig {
  type: 'open';
  voiceID?: string;
}

interface TemplateSettingsModalConfig {
  type: 'open';
  templateID?: string;
}

interface ComposeEditorPaneProps {
  additionalToolbarButtons?: React.ReactNode;
  isWaitingForResponse: boolean;
  artifacts: ComposeArtifact[];
  setMarkdownContent: (markdownContent: string) => void;
  setDraftConfig: (config: ComposeAssistantDraftConfig) => void;
  postUpdateDraftWithVoice: (voiceID: string) => void;
  postUpdateDraftWithTemplate: (templateID: string) => void;
  messagesHistory: ComposeAssistantConversationMessage[];
  currentSessionID: string;
  postUserMessage: (messageParams: PostUserMessageParams) => void;
  setInputContext: (context: InputContext | undefined) => void;
  markdownDraft?: string;
  hideButtons?: boolean;
}
export const ComposeEditorPane = memo(
  ({
    additionalToolbarButtons,
    isWaitingForResponse,
    artifacts,
    setMarkdownContent,
    setDraftConfig,
    postUpdateDraftWithVoice,
    postUpdateDraftWithTemplate,
    messagesHistory,
    currentSessionID,
    postUserMessage,
    setInputContext,
    markdownDraft,
    hideButtons,
  }: ComposeEditorPaneProps) => {
    const { logComposeEvent: logEditorPaneEvent } = useComposeAnalyticsContext({
      actionSurfaceComponent: 'compose_editor_pane',
      currentSessionID,
    });
    const { logComposeEvent: logVoicesPaneEvent } = useComposeAnalyticsContext({
      actionSurfaceComponent: 'compose_voices_pane',
      currentSessionID,
    });
    const { logComposeEvent: logTemplatesPaneEvent } =
      useComposeAnalyticsContext({
        actionSurfaceComponent: 'assist_templates',
        currentSessionID,
      });
    const { voices, saveVoice, deleteVoice } = useComposeVoicesContext();
    const { templates, saveTemplate, deleteTemplate } =
      useAssistTemplatesContext();

    const [pendingVoiceID, setPendingVoiceID] = useState<string | undefined>();
    const [openVoiceSettingsConfig, setOpenVoiceSettingsConfig] = useState<
      VoiceSettingsModalConfig | undefined
    >();
    const [openTemplateSettings, setOpenTemplateSettings] = useState<
      TemplateSettingsModalConfig | undefined
    >();

    const markdownArtifact = useMemo(
      () => getFirstMarkdownArtifact(artifacts),
      [artifacts],
    );

    const handleTriggerSelectionAction = useHandleTriggerSelectionAction({
      setInputContext,
      postUserMessage,
      currentSessionID,
    });

    const handleChangeVoiceID = useCallback(
      (voiceID: string) => {
        postUpdateDraftWithVoice(voiceID);
      },
      [postUpdateDraftWithVoice],
    );

    const handleDeleteVoice = useCallback(
      (voiceId: string) => {
        if (markdownArtifact?.draftConfig.voiceID === voiceId) {
          handleChangeVoiceID(DEFAULT_PRECONFIGURED_VOICE_ID);
        }
        deleteVoice(voiceId);
        logVoicesPaneEvent(PAP_Click_VoiceDeleteButton(), {
          actionSurfaceComponent: 'compose_voices_pane',
        });
        setOpenVoiceSettingsConfig(undefined);
      },
      [deleteVoice, logVoicesPaneEvent, handleChangeVoiceID, markdownArtifact],
    );

    const handleCreateNewVoice = useCallback(() => {
      setOpenVoiceSettingsConfig({ type: 'open', voiceID: undefined });
    }, []);

    const handleSaveVoice = useCallback(
      async (voice: ComposeVoice) => {
        await saveVoice(voice);
        setDraftConfig({
          ...markdownArtifact?.draftConfig,
          voiceID: voice.id,
        });
        setPendingVoiceID(voice.id);
      },
      [saveVoice, markdownArtifact?.draftConfig, setDraftConfig],
    );
    useEffect(() => {
      // Additional state update prevents using stale voice cache to post draft with new voice
      if (pendingVoiceID && voices?.some((v) => v.id === pendingVoiceID)) {
        postUpdateDraftWithVoice(pendingVoiceID);
        setPendingVoiceID(undefined);
      }
    }, [pendingVoiceID, voices, postUpdateDraftWithVoice]);
    const handleOpenVoiceSettings = useCallback(
      (voiceID: string | undefined) => {
        logEditorPaneEvent(
          PAP_Click_VoiceMenuOption({
            actionType: 'edit',
          }),
        );
        setOpenVoiceSettingsConfig({ type: 'open', voiceID });
      },
      [logEditorPaneEvent],
    );
    const handleCloseVoicesSettings = useCallback(() => {
      setOpenVoiceSettingsConfig(undefined);
    }, []);
    const handleOpenTemplateSettings = useCallback(
      (templateID?: string) => {
        logTemplatesPaneEvent(PAP_Click_TemplateMenuButton());
        setOpenTemplateSettings({ type: 'open', templateID });
      },
      [logTemplatesPaneEvent],
    );
    const handleDeleteTemplate = useCallback(
      (templateId: string) => {
        deleteTemplate(templateId);
        setOpenTemplateSettings(undefined);
      },
      [deleteTemplate],
    );

    const handleSaveTemplate = useCallback(
      async (template: AssistCustomTemplate) => {
        const id = await saveTemplate(template);
        // TODO (Will) OTCA-154
        // logTemplatesPaneEvent(
        //   PAP_Click_TemplateSaveButton({
        //     actionType: template.accessType,
        //   }),
        // );
        handleOpenTemplateSettings();
        return id;
      },
      [
        saveTemplate,
        // logTemplatesPaneEvent,
        handleOpenTemplateSettings,
      ],
    );

    const handleCloseTemplatesSettings = useCallback(() => {
      setOpenTemplateSettings(undefined);
    }, []);

    const handleChangeTemplateID = useCallback(
      (templateID: string) => {
        postUpdateDraftWithTemplate(templateID);
        handleCloseTemplatesSettings();
      },
      [postUpdateDraftWithTemplate, handleCloseTemplatesSettings],
    );

    const [createdTemplateSnackbarMsg, setCreatedTemplateSnackbarMsg] =
      useState<undefined | string>();
    const handleSetCreatedTemplateSnackbarMsg = useCallback(
      (msg: string | undefined) => {
        setCreatedTemplateSnackbarMsg(msg);
      },
      [setCreatedTemplateSnackbarMsg],
    );
    return (
      <>
        <ComposeEditor
          isWaitingForResponse={isWaitingForResponse}
          markdownContent={
            markdownDraft || markdownArtifact?.markdownContent || ''
          }
          onChangeContent={setMarkdownContent}
          onTriggerSelectionAction={handleTriggerSelectionAction}
          currentVoiceID={markdownArtifact?.draftConfig.voiceID}
          customVoices={voices}
          onChangeVoiceID={handleChangeVoiceID}
          onCreateNewVoice={handleCreateNewVoice}
          onOpenVoiceSettings={handleOpenVoiceSettings}
          onOpenTemplateSettings={handleOpenTemplateSettings}
          logComposeEvent={logEditorPaneEvent}
          additionalToolbarButtons={additionalToolbarButtons}
          disabled={messagesHistory.length === 0}
          hideButtons={hideButtons}
        />
        {openVoiceSettingsConfig && (
          <VoiceSettingsModal
            voices={voices || []}
            saveVoice={handleSaveVoice}
            onDeleteVoice={handleDeleteVoice}
            initialVoiceID={openVoiceSettingsConfig.voiceID}
            onRequestClose={handleCloseVoicesSettings}
            logComposeEvent={logVoicesPaneEvent}
          />
        )}
        {openTemplateSettings?.type === 'open' && (
          <TemplateSettingsModal
            isOpen={true}
            templates={templates || []}
            onRequestClose={handleCloseTemplatesSettings}
            logComposeEvent={logTemplatesPaneEvent}
            saveTemplate={handleSaveTemplate}
            onDeleteTemplate={handleDeleteTemplate}
            onUseTemplate={handleChangeTemplateID}
            currentTemplateID={markdownArtifact?.draftConfig.templateID}
            setCreatedTemplateSnackbarMsg={handleSetCreatedTemplateSnackbarMsg}
          />
        )}
        {/* This snackbar needs to render across the entire page so using the shared SnackbarView is not an option */}
        {createdTemplateSnackbarMsg ? (
          <Snackbar.Position>
            <Snackbar
              open
              preferComposition
              timeout={3000}
              onRequestClose={() => {
                setCreatedTemplateSnackbarMsg(undefined);
              }}
            >
              <Snackbar.Content>
                <Snackbar.Accessory>
                  <UIIcon src={FailLine} />
                </Snackbar.Accessory>
                <Snackbar.Message>
                  {createdTemplateSnackbarMsg}
                </Snackbar.Message>
                <Snackbar.Actions>
                  <Button
                    variant="transparent"
                    size="medium"
                    tone="neutral"
                    onClick={() => setCreatedTemplateSnackbarMsg(undefined)}
                  >
                    {i18n.t('compose_assistant_file_uploader_snackbar_dismiss')}
                  </Button>
                </Snackbar.Actions>
              </Snackbar.Content>
            </Snackbar>
          </Snackbar.Position>
        ) : (
          <></>
        )}
      </>
    );
  },
);
ComposeEditorPane.displayName = 'ComposeEditorPane';

function useHandleTriggerSelectionAction({
  setInputContext,
  postUserMessage,
  currentSessionID,
}: {
  setInputContext: (context: InputContext | undefined) => void;
  postUserMessage: (messageParams: PostUserMessageParams) => void;
  currentSessionID: string;
}) {
  const { logComposeEvent } = useComposeAnalyticsContext({
    actionSurfaceComponent: 'compose_editor_pane',
    currentSessionID,
  });
  return useCallback(
    (action: SelectionAction) => {
      logComposeEvent(
        PAP_Click_FormatButton({
          actionType: action.type,
        }),
      );
      switch (action.type) {
        case 'make-longer':
        case 'make-shorter':
        case 'rewrite':
          {
            const actionUserText = getSelectionActionUserText(action.type);
            const actionPromptString = getSelectionActionPromptString(
              action.type,
            );
            postUserMessage({
              text: actionUserText,
              rawPromptText: getSelectionPromptString(
                actionPromptString,
                action.selectedText,
              ),
              mustGenerateDraft: false,
              mustIncludeSourceContents: false,
              isTextSelectionMenuAction: true,
              actionContext: {
                type: 'compose_selection_edit',
                selectedText: action.selectedText,
              },
            });
          }
          break;
        case 'prompt':
          setInputContext({
            type: 'selection',
            selectedText: action.selectedText,
          });
          break;
        default:
          action.type satisfies never;
          throw new Error(`Unknown action type: ${action}`);
      }
    },
    [postUserMessage, setInputContext, logComposeEvent],
  );
}

function getSelectionActionUserText(
  actionType: 'rewrite' | 'make-longer' | 'make-shorter',
) {
  switch (actionType) {
    case 'make-longer':
      return i18n.t('compose_selection_action_make_longer');
    case 'make-shorter':
      return i18n.t('compose_selection_action_make_shorter');
    case 'rewrite':
      return i18n.t('compose_selection_action_rewrite');
    default:
      actionType satisfies never;
      throw new Error(`Unknown selection action type: ${actionType}`);
  }
}
