import { Text, Title } from '@dropbox/dig-components/typography';
import { Box, Stack } from '@dropbox/dig-foundations';
import { OnboardingHeader } from '@mirage/growth/components/OnboardingHeader';
import { Stepper } from '@mirage/growth/components/Stepper';
import classnames from 'classnames';
import { useOnboardingStyles } from '../hooks/useOnboardingStyles';
import styles from './SelfServeOnboardingLayout.module.css';

type Props = {
  title?: string;
  description?: string | JSX.Element;
  totalSteps: number;
  currentStep: number;
  width?: string;
  displayNavigation?: boolean;
  footerContent?: React.ReactNode;
  actionButtons?: React.ReactNode;
  children?: React.ReactNode;
};

export const SelfServeOnboardingLayout = ({
  title = '',
  totalSteps,
  currentStep,
  description,
  footerContent,
  actionButtons,
  width = '406px',
  displayNavigation = true,
  children,
}: Props) => {
  const { marginBottom } = useOnboardingStyles();

  return (
    <Box
      className={styles.selfServeOnboarding}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      style={{ paddingBottom: `${marginBottom}px` }}
    >
      <Box
        display="flex"
        className={styles.dashHeader}
        width={'100%'}
        padding="Micro XLarge"
        borderColor="Border Subtle"
        borderBottom="Solid"
        alignItems="center"
      >
        <OnboardingHeader className={styles.logo} displayGlyph={false} />
        {displayNavigation && (
          <Stepper currentStep={currentStep + 1} totalSteps={totalSteps} />
        )}
        <span className={styles.spacer}></span>
      </Box>
      <Box
        overflowY="auto"
        flexGrow={1}
        width="100%"
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        {displayNavigation && (
          <Stack
            className={styles.contentHeader}
            gap="Macro XSmall"
            justify="center"
            align="center"
            display="flex"
          >
            <Title
              size="large"
              weightVariant="emphasized"
              className={styles.textCentered}
            >
              {title}
            </Title>
            {description && (
              <Stack display="flex" justify="center" align="center">
                <Text
                  variant="paragraph"
                  size="large"
                  color="subtle"
                  className={classnames(
                    styles.atlasGrotesk,
                    styles.textCentered,
                    styles.subHeader,
                  )}
                >
                  {description}
                </Text>
              </Stack>
            )}
          </Stack>
        )}

        <Box
          display="flex"
          flexDirection="column"
          width={'100%'}
          style={{ maxWidth: width }}
          flexGrow={1}
        >
          {children}
        </Box>
      </Box>
      {displayNavigation && (
        <>
          {footerContent}
          <div className={styles.bottomContainer}>
            <Box
              display="flex"
              flexGrow={1}
              width={'100%'}
              justifyContent={'center'}
              padding="Micro XLarge"
              borderColor="Border Subtle"
              borderTop="Solid"
            >
              {actionButtons}
            </Box>
          </div>
        </>
      )}
    </Box>
  );
};
