import { fetchMultimediaPreview } from '@mirage/service-dbx-api';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { atom, useAtom } from 'jotai';
import { useEffect, useState } from 'react';

const previewUrlCacheAtom = atom<Record<string, string>>({});
export const useMultimediaPreview = (previewUrl?: string, size?: number) => {
  const [base64Image, setBase64Image] = useState<string | undefined>(undefined);
  const [base64Loading, setBase64Loading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [previewUrlCache, setPreviewUrlCache] = useAtom(previewUrlCacheAtom);

  const getPreviewUrlFromCache = async (url?: string) => {
    if (!url) {
      return undefined;
    }
    if (previewUrlCache[url]) {
      return previewUrlCache[url];
    }
    const base64Preview = await fetchMultimediaPreview(
      url,
      EnvCtx?.surface === 'desktop',
      size,
    );
    if (base64Preview) {
      setPreviewUrlCache((prev) => ({
        ...prev,
        [url]: base64Preview,
      }));
    }
    return base64Preview;
  };

  useEffect(() => {
    let isMounted = true;
    const fetchBase64 = async () => {
      try {
        const base64Preview = await getPreviewUrlFromCache(previewUrl);

        if (!isMounted) {
          return;
        }

        // The preview service was unable to extract the image
        if (base64Preview?.startsWith('data:text')) {
          setHasError(true);
          setBase64Loading(false);
          return;
        }
        setBase64Image(base64Preview);
      } catch (e) {
        setHasError(true);
      } finally {
        setBase64Loading(false);
      }
    };
    setHasError(false);
    if (previewUrl) {
      setBase64Image(undefined);
      setBase64Loading(true);
      fetchBase64();
    } else {
      setBase64Loading(false);
    }
    return () => {
      isMounted = false;
    };
  }, [previewUrl]);

  return {
    base64Image,
    base64Loading,
    hasError,
  };
};
