import { Button } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { FileTemplateLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { PAP_Click_FormatButton } from '@mirage/analytics/events/types/click_format_button';
import { ExportButton } from '@mirage/mosaics/ComposeAssistant/components/editor/ExportButton';
import { SelectionToolbarPlugin } from '@mirage/mosaics/ComposeAssistant/components/editor/SelectionToolbar';
import { StaticToolbarPlugin } from '@mirage/mosaics/ComposeAssistant/components/editor/StaticToolbarPlugin';
import { VoiceSelector } from '@mirage/mosaics/ComposeAssistant/components/editor/VoiceSelector';
import { WaitingForResponsePlugin } from '@mirage/mosaics/ComposeAssistant/components/editor/WaitingForResponsePlugin';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { tagged } from '@mirage/service-logging';
import { DEFAULT_PRECONFIGURED_VOICE_ID } from '@mirage/shared/compose/compose-session';
import { LexicalContentEditable } from '@mirage/shared/compose/editor/LexicalContentEditable';
import { LexicalEditor } from '@mirage/shared/compose/editor/LexicalEditor';
import { useClipboardCopyListenerOnRef } from '@mirage/shared/hooks/useClipboardCopyListenerOnRef';
import i18n from '@mirage/translations';
import { memo, useCallback } from 'react';
import styles from './ComposeEditor.module.css';
import { ComposeSelectionLoggerPlugin } from './ComposeSelectionLoggerPlugin';

import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type { SelectionAction } from '@mirage/mosaics/ComposeAssistant/components/editor/SelectionToolbar';
import type { ComposeVoice } from '@mirage/shared/compose/compose-voice';

const logger = tagged('ComposeEditor');

const STATIC_TOOLBAR_HEIGHT = 58;

interface ComposeEditorProps {
  isWaitingForResponse: boolean;
  markdownContent: string;
  onChangeContent: (markdownContent: string) => void;
  onTriggerSelectionAction: (action: SelectionAction) => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
  customVoices: ComposeVoice[] | undefined;
  currentVoiceID: string | undefined;
  onChangeVoiceID: (voiceID: string) => void;
  onCreateNewVoice: () => void;
  onOpenVoiceSettings: (voiceID: string | undefined) => void;
  onOpenTemplateSettings: () => void;
  disabled: boolean;
  additionalToolbarButtons?: React.ReactNode;
  hideButtons?: boolean;
}
export const ComposeEditor = memo(
  ({
    isWaitingForResponse,
    markdownContent,
    onChangeContent,
    onTriggerSelectionAction,
    logComposeEvent,
    customVoices,
    currentVoiceID,
    onChangeVoiceID,
    onCreateNewVoice,
    onOpenVoiceSettings,
    onOpenTemplateSettings,
    disabled,
    additionalToolbarButtons,
    hideButtons,
  }: ComposeEditorProps) => {
    const handleCopy = useCallback(() => {
      logComposeEvent(
        PAP_Click_FormatButton({
          actionType: 'copy_from_keyboard',
        }),
      );
    }, [logComposeEvent]);
    const clipboardListener = useClipboardCopyListenerOnRef(handleCopy);
    const onRef = useCallback(
      (el: HTMLDivElement) => {
        if (el) {
          clipboardListener(el);
        }
      },
      [clipboardListener],
    );
    const templatesEnabled =
      useFeatureFlagValue('dash_2025_02_20_assist_templates') === 'ON';

    return (
      <LexicalEditor
        namespace="ComposeNewPost"
        editable={!disabled}
        onError={logger.error}
        contentEditable={
          <div className={styles.contentEditableScroller}>
            <div style={{ position: 'relative' }} ref={onRef}>
              <LexicalContentEditable />
            </div>
          </div>
        }
        placeholder={
          <div className={styles.placeholderWrapper}>
            <div className={styles.placeholder}>
              <h2>{i18n.t('compose_editor_placeholder_title')}</h2>
              <p>{i18n.t('compose_editor_placeholder_body')}</p>
            </div>
          </div>
        }
        markdownContent={markdownContent}
        onChangeContent={onChangeContent}
        isClickableLinks={true}
        toolbarPlugin={
          <StaticToolbarPlugin
            logComposeEvent={logComposeEvent}
            additionalButtons={
              <>
                {!hideButtons && (
                  <VoiceSelector
                    currentVoiceID={
                      currentVoiceID || DEFAULT_PRECONFIGURED_VOICE_ID
                    }
                    customVoices={customVoices}
                    onChangeVoiceID={onChangeVoiceID}
                    onCreateNewVoice={onCreateNewVoice}
                    onOpenVoiceSettings={onOpenVoiceSettings}
                    disabled={disabled}
                    logComposeEvent={logComposeEvent}
                  />
                )}
                {!hideButtons && templatesEnabled && (
                  <Button
                    variant="borderless"
                    hasNoUnderline
                    withDropdownIcon
                    onClick={() => {
                      onOpenTemplateSettings();
                    }}
                    className={styles.templatesButton}
                  >
                    <UIIcon
                      src={FileTemplateLine}
                      className={styles.templatesIcon}
                    />
                    {i18n.t('assist_templates_menu_title')}
                  </Button>
                )}
                <div className={styles.spacer} />
                <ExportButton
                  logComposeEvent={logComposeEvent}
                  disabled={disabled}
                />
                {additionalToolbarButtons}
              </>
            }
          />
        }
        additionalPlugins={
          <>
            <ComposeSelectionLoggerPlugin logComposeEvent={logComposeEvent} />
            <SelectionToolbarPlugin
              offsetTop={STATIC_TOOLBAR_HEIGHT}
              onTriggerSelectionAction={onTriggerSelectionAction}
              logComposeEvent={logComposeEvent}
            />
            <WaitingForResponsePlugin
              isWaitingForResponse={isWaitingForResponse}
            />
          </>
        }
      />
    );
  },
);
ComposeEditor.displayName = 'ComposeEditor';
