import { IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine } from '@dropbox/dig-icons/assets';
import { ComposeEditorPane } from '@mirage/mosaics/ComposeAssistant/containers/ComposeEditorPane';
import i18n from '@mirage/translations';
import { memo } from 'react';
import { useComposeCurrentSessionContext } from '../../data/current-session/ComposeCurrentSessionContext';
import styles from './FullScreenComposeEditorPane.module.css';

interface FullScreenComposeEditorPaneProps {
  onCloseEditorPane: () => void;
}
export const FullScreenComposeEditorPane = memo(
  ({ onCloseEditorPane }: FullScreenComposeEditorPaneProps) => {
    const {
      currentSessionID,
      artifacts,
      isWaitingForResponse,
      setMarkdownContent,
      setDraftConfig,
      postUpdateDraftWithVoice,
      postUpdateDraftWithTemplate,
      messagesHistory,
      setInputContext,
      postUserMessage,
    } = useComposeCurrentSessionContext();
    const closeEditorButton = (
      <IconButton
        variant="borderless"
        size="medium"
        onClick={onCloseEditorPane}
        aria-label={i18n.t('assistant_chat_view_toggle')}
      >
        <UIIcon src={CloseLine} />
      </IconButton>
    );
    return (
      <div className={styles.FullScreenComposeEditorPane}>
        <ComposeEditorPane
          isWaitingForResponse={isWaitingForResponse}
          artifacts={artifacts}
          setMarkdownContent={setMarkdownContent}
          setDraftConfig={setDraftConfig}
          postUpdateDraftWithVoice={postUpdateDraftWithVoice}
          postUpdateDraftWithTemplate={postUpdateDraftWithTemplate}
          messagesHistory={messagesHistory}
          currentSessionID={currentSessionID}
          setInputContext={setInputContext}
          postUserMessage={postUserMessage}
          additionalToolbarButtons={closeEditorButton}
          hideButtons
        />
      </div>
    );
  },
);
FullScreenComposeEditorPane.displayName = 'FullScreenComposeEditorPane';
