import { ContentIcon } from '@dropbox/dash-component-library';
import { Avatar } from '@dropbox/dig-components/avatar';
import { getInitialsFromPerson } from '@mirage/shared/account';
import { SearchResultIcon } from '@mirage/shared/icons';

import type { ContentIconProps } from '@dropbox/dash-component-library';
import type { SearchResult } from '@mirage/service-dbx-api';
import type { AuthorInfo } from '@mirage/shared/search/search-result';

export type PersonIconProps = {
  result: SearchResult;
  person: AuthorInfo;
  size?: ContentIconProps['size'];
};

export const PersonIcon = ({
  result,
  person,
  size = 'large',
}: PersonIconProps) => {
  const initials = getInitialsFromPerson({
    displayName: person.displayName || person.aliasHandle,
    email: person.email || '',
  });
  const avatar = (
    <Avatar
      size={size === 'xlarge' ? 'large' : size}
      src={person.profilePhotoUrl}
      alt={person.displayName}
    >
      {initials}
    </Avatar>
  );

  const badgeIcon = <SearchResultIcon size="100%" result={result} />;

  return (
    <ContentIcon
      icon={avatar}
      badgeIcon={badgeIcon}
      size={size === 'xlarge' ? 'large' : size}
      hasBackground
      shouldConstrainContent={false}
      hasBorder={false}
      shapeVariant="circle"
    />
  );
};
