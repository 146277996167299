import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Box } from '@dropbox/dig-foundations';
import i18n from '@mirage/translations';
import { enforceExhaustive } from '../util';
import { useManagedInviteFormWithInitialData } from './hooks/useManagedInviteForm';
import { InviteForm } from './InviteForm';
import styles from './InviteModal.module.css';
import {
  InviteModalSubmitPartialSuccessContent,
  InviteModalSubmitSuccessContent,
} from './InviteModalPostSubmit';
import { OverQuota } from './OverQuota';
import { DashInviteFormSource, InviteFormStatus } from './types';
import { maybeMockFetchWithTestData } from './utils/mockFetch';

/**
 * Setting up api stub gated env + manual flag
 * This is considered a temporary measure for dev work until we evaluate more robust options
 */
maybeMockFetchWithTestData();

export function InviteModal({ onClose }: { onClose: () => void }) {
  const {
    handleSubmit,
    inviteFormStatus,
    managedInviteFormProps,
    submissionResult,
  } = useManagedInviteFormWithInitialData({
    initialMessage: i18n.t('optional_message_default'),
    initialEmptyInputCount: 1,
    dashInviteFormSource: DashInviteFormSource.DASH_HOMEPAGE_MODAL,
  });

  const renderModalContent = () => {
    switch (inviteFormStatus) {
      case InviteFormStatus.SUBMITTED:
        if (submissionResult) {
          if (submissionResult.failedEmails.length > 0) {
            return (
              <>
                <InviteModalSubmitPartialSuccessContent.Header
                  {...submissionResult}
                  onClose={onClose}
                />
                <InviteModalSubmitPartialSuccessContent.Body
                  {...submissionResult}
                  onClose={onClose}
                />
                <InviteModalSubmitPartialSuccessContent.Footer
                  onClose={onClose}
                />
              </>
            );
          }
          return (
            <>
              <InviteModalSubmitSuccessContent.Header
                {...submissionResult}
                onClose={onClose}
              />
              <InviteModalSubmitSuccessContent.Body
                {...submissionResult}
                onClose={onClose}
              />
              <InviteModalSubmitSuccessContent.Footer onClose={onClose} />
            </>
          );
        }
        return null;
      case InviteFormStatus.LOADING:
        return (
          <>
            <Modal.Header hasBottomSpacing="title-small">
              <Modal.Title>
                {'Some Placeholder Text for this Modal'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <InviteModalLoadingSpinner />
            </Modal.Body>
          </>
        );
      case InviteFormStatus.CAN_SUBMIT:
      case InviteFormStatus.SUBMITTING:
      case InviteFormStatus.CANNOT_SUBMIT:
        return (
          <>
            <Modal.Header hasBottomSpacing="title-small">
              <Modal.Title>
                {'Some Placeholder Text for this Modal'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {managedInviteFormProps ? (
                <InviteForm {...managedInviteFormProps} />
              ) : (
                <InviteModalLoadingSpinner />
              )}
            </Modal.Body>
            <Modal.Footer
              preferComposition={true}
              className={styles.inviteModalFooter}
            >
              <Button
                variant="opacity"
                disabled={inviteFormStatus === InviteFormStatus.SUBMITTING}
                onClick={onClose}
              >
                {'Cancel'}
              </Button>
              <Button
                variant="primary"
                disabled={inviteFormStatus !== InviteFormStatus.CAN_SUBMIT}
                isLoading={inviteFormStatus === InviteFormStatus.SUBMITTING}
                onClick={
                  inviteFormStatus === InviteFormStatus.CAN_SUBMIT
                    ? handleSubmit
                    : undefined
                }
              >
                {'Invite'}
              </Button>
            </Modal.Footer>
          </>
        );

      case InviteFormStatus.OVER_QUOTA:
        return (
          <>
            <Modal.Header hasBottomSpacing="title-small">
              <Modal.Title>
                {'Some Placeholder Text for this Modal'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <OverQuota
                licenseCount={
                  managedInviteFormProps?.initialInviteFormData.licenseCount ??
                  0
                }
                isAdmin={
                  !!managedInviteFormProps?.initialInviteFormData
                    .canInviteAllTeamMembers
                }
              />
            </Modal.Body>
          </>
        );
      default:
        return enforceExhaustive(inviteFormStatus);
    }
  };

  const showCloseButton = !(
    inviteFormStatus === InviteFormStatus.SUBMITTED &&
    submissionResult &&
    submissionResult.failedEmails.length === 0
  );

  return (
    <Modal
      open={true}
      withCloseButton={showCloseButton ? 'close' : undefined}
      onRequestClose={onClose}
      isCentered
    >
      {renderModalContent()}
    </Modal>
  );
}

const InviteModalLoadingSpinner: React.FC = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={styles.inviteModalLoadingSpinnerContainer}
    >
      <Spinner size="large" aria-valuetext="Loading" />
    </Box>
  );
};
