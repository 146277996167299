import { Box, useTheme } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CommentLine,
  EditLine,
  PaperDocLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { ToggleExpandButton } from '@mirage/mosaics/ComposeAssistant/components/layouts/ToggleExpandButton';
import { globalBannersHeightAtom } from '@mirage/mosaics/GlobalBanners/globalBannersAtom';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useIsSmallSizeForSidebar } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { Resizable } from 're-resizable';
import { memo, useEffect, useState } from 'react';
import styles from './ChatPageLayout.module.css';

import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';

interface ChatPageWrapperProps {
  children: React.ReactNode;
}
export const ChatPageWrapper = memo(({ children }: ChatPageWrapperProps) => {
  const isDesktop = EnvCtx.surface === 'desktop';
  const globalBannersHeight = useAtomValue(globalBannersHeightAtom);

  // Set the glboal banner height as a CSS variable
  const wrapperStyle = {
    '--global-banners-height': `${globalBannersHeight}px`,
  } as React.CSSProperties;

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.isDesktop]: isDesktop,
      })}
      style={wrapperStyle}
    >
      {children}
    </div>
  );
});
ChatPageWrapper.displayName = 'ChatPageWrapper';

interface ChatGridProps {
  sidePane: React.ReactNode;
  sideBar?: React.ReactNode;
  rightPane?: React.ReactNode;
  setExpandRightPane?: (expanded: boolean) => void;
  disableExpandRightPane?: boolean;
  didJustSendFirstWriteCommand?: boolean;
  keepSideBarExpanded?: boolean;
  setKeepSideBarExpanded?: (expanded: boolean) => void;
  logChatEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
  rightPaneExpanded?: boolean;
  showToggle?: boolean;
}
export const ChatGrid = memo(
  ({
    sidePane,
    sideBar,
    rightPane,
    setExpandRightPane,
    disableExpandRightPane,
    didJustSendFirstWriteCommand = false,
    keepSideBarExpanded = false,
    setKeepSideBarExpanded,
    logChatEvent,
    rightPaneExpanded,
    showToggle,
  }: ChatGridProps) => {
    const theme = useTheme();
    const isSmallSize = useIsSmallSizeForSidebar();
    const [showHandle, setShowHandle] = useState(true);
    const [fadeOut, setFadeOut] = useState(false);
    const [isEditorView, setIsEditorView] = useState(false);

    // On mobile the sidebar gets absolute positioning and a clickable overlay is shown
    const [showDismissalOverlay, setShowDismissalOverlay] = useState(false);
    useEffect(() => {
      setShowDismissalOverlay(isSmallSize && keepSideBarExpanded);
    }, [isSmallSize, keepSideBarExpanded]);

    useEffect(() => {
      let fadeOutTimer: NodeJS.Timeout;
      let hideTimer: NodeJS.Timeout;
      const handleMouseMove = () => {
        clearTimeout(hideTimer);
        clearTimeout(fadeOutTimer);
        setShowHandle(true);
        setFadeOut(false);
        hideTimer = setTimeout(() => {
          setFadeOut(true);
          fadeOutTimer = setTimeout(() => {
            setShowHandle(false);
          }, 300);
        }, 1000);
      };
      window.addEventListener('mousemove', handleMouseMove);
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        clearTimeout(hideTimer);
        clearTimeout(fadeOutTimer);
      };
    }, []);
    const toggleView = () => {
      setIsEditorView((prev) => !prev);
    };
    return (
      <div className={styles.ComposeAssistantGrid}>
        {showDismissalOverlay && setKeepSideBarExpanded && (
          <Box
            className={styles.ComposeAssistantOverlay}
            onClick={() => setKeepSideBarExpanded(false)}
          />
        )}
        <div className={styles.ComposeAssistantGridSideBar}>{sideBar}</div>
        {isSmallSize && rightPane ? (
          // Mobile layout
          <div className={styles.ComposeAssistantMobileContainer}>
            <div className={styles.ComposeAssistantMobilePane}>
              {isEditorView ? rightPane : sidePane}
            </div>
            <button
              className={styles.ComposeAssistantMobileToggleButton}
              onClick={toggleView}
              aria-label={
                isEditorView
                  ? i18n.t('assistant_chat_view_toggle')
                  : i18n.t('assistant_editor_view_toggle')
              }
            >
              <UIIcon src={isEditorView ? CommentLine : EditLine} />
            </button>
          </div>
        ) : (
          // Desktop layout
          <>
            {rightPane ? (
              <Resizable
                defaultSize={{
                  width: didJustSendFirstWriteCommand ? '45%' : '35%',
                }}
                minWidth={280}
                enable={{ right: true }}
                handleComponent={{
                  right: showHandle ? (
                    <div
                      className={styles.ComposeAssistantResizeHandleContainer}
                    >
                      <div
                        className={classNames(
                          styles.ComposeAssistantResizeHandle,
                          {
                            [styles.ComposeAssistantResizeHandleFadeOut]:
                              fadeOut,
                          },
                        )}
                      />
                    </div>
                  ) : undefined,
                }}
              >
                {sidePane}
              </Resizable>
            ) : (
              <div
                className={classNames(
                  styles.ComposeAssistantGridSidePane,
                  styles.ComposeAssistantGridSidePaneNoResize,
                )}
                style={{ width: '100%' }}
              >
                {sidePane}
              </div>
            )}
            {showToggle && setExpandRightPane && (
              <div className={styles.RightPaneToggleContainer}>
                <ToggleExpandButton
                  setExpanded={setExpandRightPane}
                  variant={rightPaneExpanded ? 'collapse' : 'expand'}
                  disabled={disableExpandRightPane}
                  logComposeEvent={logChatEvent}
                  icon={PaperDocLine}
                />
              </div>
            )}
            {rightPane && (
              <div
                className={classNames({
                  [styles.ComposeAssistantGridRightPaneExpanded]: !!rightPane,
                  [styles.ComposeAssistantGridRightPaneDark]:
                    theme.mode === 'dark',
                })}
              >
                {rightPane}
              </div>
            )}
          </>
        )}
      </div>
    );
  },
);
ChatGrid.displayName = 'ChatGrid';
