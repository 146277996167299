// import { Button } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
// import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CheckmarkLine,
  ChevronDownLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { ConversationInput } from '@mirage/mosaics/Chat/components/chat/ConversationInput';
import { ConversationMessages } from '@mirage/mosaics/Chat/components/chat/ConversationMessages';
import { ConversationMessageSourceChips } from '@mirage/mosaics/Chat/components/chat/ConversationMessageSourceChips';
import { AddSourcesModal } from '@mirage/mosaics/Chat/components/compose-sources/AddSourcesModal';
import { useComposeSourcesCache } from '@mirage/mosaics/Chat/data/ComposeSourcesCache';
import { WorkflowAgentMessageHeader } from '@mirage/mosaics/ComposeAssistant/components/workflow-agent/WorkflowAgentMessageHeader';
import { postUserMessage } from '@mirage/service-conversation';
import useConversation from '@mirage/service-conversation/hooks/useConversation';
import { useWorkflowAgent } from '@mirage/service-conversation/hooks/useWorkflowAgents';
import { convertToChatMessage } from '@mirage/service-conversation/utils/convertToChatMessage';
import { getSourceUUID } from '@mirage/shared/compose/compose-session';
import { isDefined } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';
import { memo, useCallback, useMemo, useState } from 'react';
import styles from './ConversationChat.module.css';

import type { ChatSource } from '@mirage/mosaics/Chat/types';
import type { ExperimentSetting } from '@mirage/service-conversation/types';

interface ConversationChatProps {
  conversationId: string;
  handleClickAdd: () => void;
  setIsAddSourceModalOpen: (isOpen: boolean) => void;
  isAddSourceModalOpen: boolean;
  variant?: 'default' | 'condensed';
  documentIds?: string[];
  model?: ExperimentSetting;
  agentId?: string;
}

const MODEL_OPTIONS = [
  { value: 'CONTROL', label: '[CONTROL] gpt-4o-mini' },
  { value: 'V1', label: '[V1] dropbox/pixtral-12b-128k' },
  { value: 'V2', label: '[V2] dropbox/pixtral-12b-32k' },
] as const;

export const ConversationChat = memo(
  ({
    variant = 'default',
    conversationId,
    handleClickAdd,
    setIsAddSourceModalOpen,
    isAddSourceModalOpen,
    documentIds = [],
    model = 'CONTROL',
    agentId,
  }: ConversationChatProps) => {
    const messages = useConversation(conversationId);
    // const { messages, followUpSuggestions } = useConversation(conversationId);
    const [manuallyAddedSources, setManuallyAddedSources] = useState<
      ChatSource[]
    >([]);
    const [selectedModel, setSelectedModel] =
      useState<ExperimentSetting>(model);

    const agent = useWorkflowAgent(agentId);
    const sourcesContentCache = useComposeSourcesCache(manuallyAddedSources);

    const logComposeEvent = useCallback(() => {
      // TODO
    }, []);

    const startingMessageNode = useMemo(() => {
      if (agent) {
        return <WorkflowAgentMessageHeader agent={agent} />;
      }
    }, [agent]);

    const manuallyAddedSourceDocumentIds = useMemo(() => {
      return manuallyAddedSources
        .map((source) => getSourceUUID(source))
        .filter(isDefined);
    }, [manuallyAddedSources]);

    const composeMessages = useMemo(
      () => messages.map(convertToChatMessage),
      [messages],
    );
    const isWaitingForResponse = useMemo(() => {
      return messages.some((message) => message.status === 'pending');
    }, [messages]);

    const handleSubmit = useCallback(
      (text: string) => {
        postUserMessage({
          conversationId,
          message: text,
          documentIds: [...documentIds, ...manuallyAddedSourceDocumentIds],
          model: selectedModel,
        });
      },
      [
        conversationId,
        documentIds,
        manuallyAddedSourceDocumentIds,
        selectedModel,
      ],
    );

    const handleCloseAddSourcesModal = useCallback(() => {
      if (isAddSourceModalOpen && manuallyAddedSources.length > 0) {
        // TODO: logging
      }
      setIsAddSourceModalOpen(false);
    }, [
      isAddSourceModalOpen,
      setIsAddSourceModalOpen,
      manuallyAddedSources.length,
    ]);

    // const handleClickFollowUpSuggestion = useCallback(
    //   (suggestion: string) => {
    //     handleSubmit(suggestion);
    //   },
    //   [handleSubmit],
    // );

    const removeSource = useCallback((sourceToRemove: ChatSource) => {
      const sourceUUID = getSourceUUID(sourceToRemove);
      if (!sourceUUID) {
        return;
      }

      setManuallyAddedSources((prevSources) =>
        prevSources.filter((s) => {
          const currentUUID = getSourceUUID(s);
          return currentUUID !== sourceUUID;
        }),
      );
    }, []);

    const addSource = useCallback((sourceToAdd: ChatSource) => {
      const uuid = getSourceUUID(sourceToAdd);
      if (!uuid) {
        return; // Skip if no UUID
      }

      setManuallyAddedSources((prevSources) => {
        const sourceUUIDs = new Set(prevSources.map(getSourceUUID));
        if (sourceUUIDs.has(uuid)) {
          return prevSources; // Skip if source already exists
        }
        return [...prevSources, sourceToAdd];
      });
    }, []);

    // const lastMessage = composeMessages[composeMessages.length - 1];
    // const isLastMessageFollowUp = !!(
    //   lastMessage?.type === 'message' &&
    //   lastMessage.followUpSuggestions &&
    //   lastMessage.followUpSuggestions.length > 0
    // );

    const displayMessages = composeMessages;

    // TODO: Decide if we want to filter out system prompts
    // .filter(
    //   (message) =>
    //     message.role !== 'user' || !message.text.startsWith('SYSTEM_PROMPT:'),
    // );

    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <ConversationMessages
            variant={variant}
            messages={displayMessages}
            startingMessageNode={startingMessageNode}
            isWaitingForResponse={isWaitingForResponse}
            progressString={undefined}
            onRemoveSource={removeSource}
            isLastMessageFollowUpSuggestions={false} // do i need this?
            artifacts={[]}
            logComposeEvent={logComposeEvent}
            onOpenArtifact={() => {}}
            renderNewSources={() => (
              <ConversationMessageSourceChips
                sources={manuallyAddedSources}
                removeSource={removeSource}
                logComposeEvent={logComposeEvent}
              />
            )}
          />
          {/* {followUpSuggestions &&
            followUpSuggestions.map((s, i) => (
              <Button
                key={i}
                variant="outline"
                onClick={() => handleClickFollowUpSuggestion(s)}
              >
                <Text>{s}</Text>
              </Button>
            ))} */}
          <Menu.Wrapper>
            {({ getContentProps, getTriggerProps }) => (
              <>
                <button
                  {...getTriggerProps()}
                  className={styles.modelSelector}
                  data-testid="model-selector"
                >
                  {
                    MODEL_OPTIONS.find(
                      (option) => option.value === selectedModel,
                    )?.label
                  }
                  <UIIcon src={ChevronDownLine} />
                </button>
                <Menu.Content {...getContentProps()} placement="bottom-start">
                  <Menu.Segment>
                    {MODEL_OPTIONS.map((option) => (
                      <Menu.ActionItem
                        key={option.value}
                        onClick={() =>
                          setSelectedModel(option.value as ExperimentSetting)
                        }
                        withLeftAccessory={
                          selectedModel === option.value ? (
                            <UIIcon src={CheckmarkLine} />
                          ) : undefined
                        }
                      >
                        {option.label}
                      </Menu.ActionItem>
                    ))}
                  </Menu.Segment>
                </Menu.Content>
              </>
            )}
          </Menu.Wrapper>
          <ConversationInput
            variant={variant}
            sources={manuallyAddedSources}
            onSubmit={handleSubmit}
            logComposeEvent={logComposeEvent}
            messages={composeMessages}
            placeholderOverride={i18n.t('conversation_prompt_placeholder')}
            handleClickAdd={handleClickAdd}
          />
          {isAddSourceModalOpen && (
            <AddSourcesModal
              sources={manuallyAddedSources}
              sourcesContentCache={sourcesContentCache}
              addSource={addSource}
              removeSource={removeSource}
              onRequestClose={handleCloseAddSourcesModal}
              logComposeEvent={logComposeEvent}
            />
          )}
        </div>
      </div>
    );
  },
);

ConversationChat.displayName = 'ConversationChat';
