import { Button } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AddCircleLine,
  ChevronLeftLine,
  DeleteForeverLine,
} from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Click_AddSourcesButton } from '@mirage/analytics/events/types/click_add_sources_button';
import { PAP_Click_NewSessionButton } from '@mirage/analytics/events/types/click_new_session_button';
import {
  ChatGrid,
  ChatPageWrapper,
} from '@mirage/mosaics/Chat/components/layouts/ChatPageLayout';
import { FullScreenComposeEditorPane } from '@mirage/mosaics/ComposeAssistant/components/layouts/FullScreenComposeEditorPane';
import { ToggleExpandButton } from '@mirage/mosaics/ComposeAssistant/components/layouts/ToggleExpandButton';
import { SideBarContainer } from '@mirage/mosaics/ComposeAssistant/components/side-bar/SideBarContainer';
import { SidePanelContainer } from '@mirage/mosaics/ComposeAssistant/components/side-panel/SidePanelContainer';
import { WorkflowAgentUI } from '@mirage/mosaics/ComposeAssistant/components/workflow-agent/WorkflowAgent';
import { ComposeConversation } from '@mirage/mosaics/ComposeAssistant/containers/ComposeConversation';
import { ComposeEditorPane } from '@mirage/mosaics/ComposeAssistant/containers/ComposeEditorPane';
import { ComposeSessionsPane } from '@mirage/mosaics/ComposeAssistant/containers/ComposeSessionsPane';
import { AssistActionsContextProvider } from '@mirage/mosaics/ComposeAssistant/data/AssistActionsContext';
import {
  ComposeAnalyticsContextProvider,
  useComposeAnalyticsContext,
} from '@mirage/mosaics/ComposeAssistant/data/ComposeAnalyticsContext';
import { ComposeSessionsContextProvider } from '@mirage/mosaics/ComposeAssistant/data/ComposeSessionsContext';
import { ComposeVoicesContextProvider } from '@mirage/mosaics/ComposeAssistant/data/ComposeVoicesContext';
import {
  ComposeCurrentSessionContextProvider,
  useComposeCurrentSessionContext,
} from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionContext';
import { getWorkflowAgent } from '@mirage/service-conversation';
import { useWorkflowAgents } from '@mirage/service-conversation/hooks/useWorkflowAgents';
import { useDashActionSurface } from '@mirage/shared/hooks/useDashActionSurface';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { useIsSmallSizeForSidebar } from '@mirage/shared/responsive/mobile';
import { usePrevious } from '@mirage/shared/util/hooks';
import i18n from '@mirage/translations';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { isEqual } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { AssistTemplatesContextProvider } from '../data/AssistTemplatesContext';

import type { FeatureLine } from '@mirage/analytics/events/enums/feature_line';
import type { TransientSource } from '@mirage/mosaics/ComposeAssistant/data/TransientSources';
import type { ComposeArtifact } from '@mirage/shared/compose/compose-session';
import type { ReactNode } from 'react';

interface ComposeAssistantPageProps {
  variant?: 'default' | 'condensed';
  transientSources?: TransientSource[];
  children?: ReactNode;
}
export const ComposeAssistantPage = memo(
  ({
    variant = 'default',
    transientSources,
    children,
  }: ComposeAssistantPageProps) => {
    return (
      <ComposeAssistPageContextProviders transientSources={transientSources}>
        {variant === 'default' ? (
          <ComposeAssistantPageContent />
        ) : (
          <ComposeAssistantPageContentCondensed />
        )}
        {children}
      </ComposeAssistPageContextProviders>
    );
  },
);
ComposeAssistantPage.displayName = 'ComposeAssistantPage';

interface ComposeAssistPageContextProvidersProps {
  transientSources?: TransientSource[];
  children?: ReactNode;
}
export const ComposeAssistPageContextProviders = memo(
  ({ transientSources, children }: ComposeAssistPageContextProvidersProps) => {
    return (
      <ComposeAnalyticsContextProvider>
        <ComposeSessionsContextProvider>
          <AssistTemplatesContextProvider>
            <AssistActionsContextProvider>
              <ComposeVoicesContextProvider>
                <ComposeCurrentSessionContextProvider
                  transientSources={transientSources}
                >
                  {children}
                </ComposeCurrentSessionContextProvider>
              </ComposeVoicesContextProvider>
            </AssistActionsContextProvider>
          </AssistTemplatesContextProvider>
        </ComposeSessionsContextProvider>
      </ComposeAnalyticsContextProvider>
    );
  },
);
ComposeAssistPageContextProviders.displayName =
  'ComposeAssistPageContextProviders';

const ComposeAssistantPageContent = memo(() => {
  const {
    currentSessionID,
    artifacts,
    showWorkflowAgent,
    setShowWorkflowAgent,
    isWaitingForResponse,
    setMarkdownContent,
    setDraftConfig,
    postUpdateDraftWithVoice,
    postUpdateDraftWithTemplate,
    messagesHistory,
    setInputContext,
    postUserMessage,
  } = useComposeCurrentSessionContext();
  const { logComposeEvent } = useComposeAnalyticsContext({
    actionSurfaceComponent: 'compose_side_bar',
    currentSessionID,
  });

  const navigate = useNavigate();
  const isNewWorkflowAgentMatch = useMatch(RoutePath.NEW_WORKFLOW_AGENT);
  const isWorkflowAgentMatch = useMatch(RoutePath.WORKFLOW_AGENT);
  const isWorkflowAgentRoute = Boolean(
    isNewWorkflowAgentMatch || isWorkflowAgentMatch,
  );
  const agentId = isWorkflowAgentMatch
    ? isWorkflowAgentMatch.params.agentId
    : undefined;
  const { deleteAgent } = useWorkflowAgents();

  const isSmallSize = useIsSmallSizeForSidebar();
  const [keepSideBarExpanded, setKeepSideBarExpanded] = useState(
    isSmallSize ? false : true,
  );
  const [didJustSendFirstWriteCommand, setDidJustSendFirstWriteCommand] =
    useState(false);
  const [rightPaneExpanded, setRightPaneExpanded] = useState(() =>
    hasNonEmptyArtifacts(artifacts),
  );
  const handleArtifactsChanged = useCallback(() => {
    // auto-expand the right pane if artifacts changed + are non-empty
    setRightPaneExpanded(hasNonEmptyArtifacts(artifacts));
    // auto-close side bar when new draft is created
    if (didJustSendFirstWriteCommand) {
      setKeepSideBarExpanded(!hasNonEmptyArtifacts(artifacts));
      setDidJustSendFirstWriteCommand(false);
    }
  }, [artifacts, didJustSendFirstWriteCommand]);
  useArtifactContentsChangedEffect(artifacts, handleArtifactsChanged);
  const { setIsAddSourceModalOpen, isAddSourceModalOpen, handleClickAdd } =
    useAddSourceHandlers();

  const sidePanelButtons =
    (!keepSideBarExpanded && (
      <>
        <ToggleExpandButton
          setExpanded={setKeepSideBarExpanded}
          variant="expand"
          logComposeEvent={logComposeEvent}
        />
        <NewSessionButton />
      </>
    )) ||
    (showWorkflowAgent && (
      <Button
        variant="borderless"
        onClick={() => {
          navigate(RoutePath.CHAT);
          setShowWorkflowAgent(false);
        }}
      >
        <UIIcon src={ChevronLeftLine} />
      </Button>
    ));

  const rightTitleActions =
    isWorkflowAgentMatch && !isNewWorkflowAgentMatch && agentId ? (
      <Button
        variant="primary"
        tone="destructive"
        onClick={async () => {
          const agent = await getWorkflowAgent(agentId);
          deleteAgent(agent);
          navigate(RoutePath.CHAT);
          setShowWorkflowAgent(false);
        }}
        withIconStart={<UIIcon src={DeleteForeverLine} />}
      >
        Delete
      </Button>
    ) : undefined;

  const additionalPadding = isSmallSize ? (
    <div style={{ width: '30px' }} />
  ) : null;
  const handleOpenArtifact = useCallback(() => {
    setRightPaneExpanded(true);
  }, []);

  // Set showWorkflowAgent to true automatically when on NEW_WORKFLOW_AGENT route
  useEffect(() => {
    if (isWorkflowAgentRoute && !showWorkflowAgent) {
      setShowWorkflowAgent(true);
    }
  }, [isWorkflowAgentRoute, showWorkflowAgent, setShowWorkflowAgent]);
  return (
    <ChatPageWrapper>
      <ChatGrid
        rightPaneExpanded={rightPaneExpanded}
        didJustSendFirstWriteCommand={didJustSendFirstWriteCommand}
        keepSideBarExpanded={keepSideBarExpanded}
        setKeepSideBarExpanded={setKeepSideBarExpanded}
        sideBar={
          <ComposeAssistSideBar
            keepExpanded={keepSideBarExpanded}
            rightPaneExpanded={rightPaneExpanded}
            setKeepExpanded={setKeepSideBarExpanded}
          />
        }
        sidePane={
          <SidePanelContainer
            leftTitleActions={sidePanelButtons}
            rightTitleActions={rightTitleActions}
            handleClickAdd={handleClickAdd}
          >
            {isWorkflowAgentRoute ? (
              <WorkflowAgentUI agentId={agentId} />
            ) : (
              <ComposeConversation
                rightPaneExpanded={rightPaneExpanded}
                setDidJustSendFirstWriteCommand={
                  setDidJustSendFirstWriteCommand
                }
                onOpenArtifact={handleOpenArtifact}
                handleClickAdd={handleClickAdd}
                setIsAddSourceModalOpen={setIsAddSourceModalOpen}
                isAddSourceModalOpen={isAddSourceModalOpen}
              />
            )}
          </SidePanelContainer>
        }
        showToggle={hasNonEmptyArtifacts(artifacts)}
        rightPane={
          rightPaneExpanded && (
            <ComposeEditorPane
              isWaitingForResponse={isWaitingForResponse}
              artifacts={artifacts}
              setMarkdownContent={setMarkdownContent}
              setDraftConfig={setDraftConfig}
              postUpdateDraftWithVoice={postUpdateDraftWithVoice}
              postUpdateDraftWithTemplate={postUpdateDraftWithTemplate}
              messagesHistory={messagesHistory}
              currentSessionID={currentSessionID}
              setInputContext={setInputContext}
              postUserMessage={postUserMessage}
              additionalToolbarButtons={additionalPadding}
            />
          )
        }
        setExpandRightPane={setRightPaneExpanded}
        disableExpandRightPane={artifacts.length === 0}
        logChatEvent={logComposeEvent}
      />
    </ChatPageWrapper>
  );
});
ComposeAssistantPageContent.displayName = 'ComposeAssistantPageContent';

interface ComposeAssistantPageContentCondensedProps {
  inputContainerRef?: React.RefObject<HTMLDivElement>;
  onOpenListActionsModal?: () => void;
}
export const ComposeAssistantPageContentCondensed = memo(
  ({
    onOpenListActionsModal,
    inputContainerRef,
  }: ComposeAssistantPageContentCondensedProps) => {
    const [showArtifactsPane, setShowArtifactsPane] = useState(false);
    const handleOpenArtifact = useCallback(() => {
      setShowArtifactsPane(true);
    }, []);
    const handleCloseEditorPane = useCallback(() => {
      setShowArtifactsPane(false);
    }, []);
    const { setIsAddSourceModalOpen, isAddSourceModalOpen, handleClickAdd } =
      useAddSourceHandlers();
    return (
      <>
        <ComposeConversation
          variant="condensed"
          rightPaneExpanded={false}
          setDidJustSendFirstWriteCommand={undefined}
          onOpenArtifact={handleOpenArtifact}
          handleClickAdd={handleClickAdd}
          setIsAddSourceModalOpen={setIsAddSourceModalOpen}
          isAddSourceModalOpen={isAddSourceModalOpen}
          inputContainerRef={inputContainerRef}
          onOpenListActionsModal={onOpenListActionsModal}
        />
        {showArtifactsPane && (
          <FullScreenComposeEditorPane
            onCloseEditorPane={handleCloseEditorPane}
          />
        )}
      </>
    );
  },
);
ComposeAssistantPageContentCondensed.displayName =
  'ComposeAssistantPageContentCondensed';

function useAddSourceHandlers() {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { dashActionSurface } = useDashActionSurface();
  const [isAddSourceModalOpen, setIsAddSourceModalOpen] = useState(false);
  const handleClickAdd = useCallback(() => {
    setIsAddSourceModalOpen(true);
    reportPapEvent(
      PAP_Click_AddSourcesButton({
        actionType: 'chat_pane',
        actionSurface: dashActionSurface,
        dashActionSurface,
        actionSurfaceComponent: 'compose_chat_pane',
        featureLine: 'assist' as FeatureLine,
      }),
    );
  }, [dashActionSurface, reportPapEvent, setIsAddSourceModalOpen]);
  return { isAddSourceModalOpen, setIsAddSourceModalOpen, handleClickAdd };
}

function useArtifactContentsChangedEffect(
  artifacts: ComposeArtifact[],
  callback: () => void,
) {
  const previousArtifacts = usePrevious(artifacts);
  useEffect(() => {
    const currentMarkdownContents = artifacts.map((a) => a.markdownContent);
    const previousMarkdownContents = previousArtifacts
      ? previousArtifacts.map((a) => a.markdownContent)
      : [];
    if (isEqual(currentMarkdownContents, previousMarkdownContents)) {
      return;
    }
    callback();
  }, [artifacts, callback, previousArtifacts]);
}

interface ComposeAssistSideBarProps {
  keepExpanded: boolean;
  setKeepExpanded: (expanded: boolean) => void;
  rightPaneExpanded: boolean;
}
export const ComposeAssistSideBar = memo(
  ({
    keepExpanded,
    setKeepExpanded,
    rightPaneExpanded,
  }: ComposeAssistSideBarProps) => {
    const { currentSessionID } = useComposeCurrentSessionContext();
    const { logComposeEvent } = useComposeAnalyticsContext({
      actionSurfaceComponent: 'compose_side_bar',
      currentSessionID,
    });
    const collapseSideBarButton = (
      <ToggleExpandButton
        logComposeEvent={logComposeEvent}
        setExpanded={setKeepExpanded}
        variant="collapse"
      />
    );
    return (
      <SideBarContainer
        rightPaneExpanded={rightPaneExpanded}
        headerActionLeft={keepExpanded ? collapseSideBarButton : undefined}
        headerActionRight={<NewSessionButton />}
        keepExpanded={keepExpanded}
      >
        <ComposeSessionsPane />
      </SideBarContainer>
    );
  },
);
ComposeAssistSideBar.displayName = 'ComposeAssistSideBar';

export const NewSessionButton = memo(() => {
  const { logComposeEvent } = useComposeAnalyticsContext({
    actionSurfaceComponent: 'compose_side_bar',
  });
  const { newSession } = useComposeCurrentSessionContext();
  return (
    <IconButtonWithTooltip
      variant="borderless"
      tooltipProps={{
        title: i18n.t('compose_new_session'),
      }}
      onClick={() => {
        newSession([]);
        logComposeEvent(PAP_Click_NewSessionButton());
      }}
    >
      <UIIcon src={AddCircleLine} />
    </IconButtonWithTooltip>
  );
});
NewSessionButton.displayName = 'NewSessionButton';

function hasNonEmptyArtifacts(artifacts: ComposeArtifact[]) {
  return artifacts.some((artifact) => artifact.markdownContent.length > 0);
}
