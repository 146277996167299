import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Click_AddSourcesButton } from '@mirage/analytics/events/types/click_add_sources_button';
import {
  ChatGrid,
  ChatPageWrapper,
} from '@mirage/mosaics/Chat/components/layouts/ChatPageLayout';
// import { ComposeEditorPane } from '@mirage/mosaics/ComposeAssistant/containers/ComposeEditorPane';
import { AssistTemplatesContextProvider } from '@mirage/mosaics/ComposeAssistant/data/AssistTemplatesContext';
import { ComposeAnalyticsContextProvider } from '@mirage/mosaics/ComposeAssistant/data/ComposeAnalyticsContext';
import { ComposeVoicesContextProvider } from '@mirage/mosaics/ComposeAssistant/data/ComposeVoicesContext';
// import useConversation from '@mirage/service-conversation/hooks/useConversation';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { useDashActionSurface } from '@mirage/shared/hooks/useDashActionSurface';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { memo, useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { v4 } from 'uuid';
// import { ConversationEditorBottomSheet } from '../components/editor/ConversationEditorBottomSheet';
import { ConversationChat } from './ConversationChat';

import type { ChatSource } from '@mirage/mosaics/Chat/types';
import type { WorkflowAgentRouteState } from '@mirage/service-conversation/types';

export interface ConversationAssistantLocationState {
  providedSources: ChatSource[];
}

export const ConversationAssistantPage = memo(() => {
  const [conversationId, setConversationId] = useState<string | undefined>(
    useSearchParams()[0].get('conversationId') || undefined,
  );
  // const { markdownDraft } = useConversation(conversationId);
  const [isAddSourceModalOpen, setIsAddSourceModalOpen] = useState(false);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { dashActionSurface } = useDashActionSurface();
  const { state }: { state?: WorkflowAgentRouteState } = useLocation();
  const { documentIds, model, agentId } = state || {};

  useEffect(() => {
    if (!conversationId) {
      setConversationId(v4());
    }
  }, [conversationId]);

  const handleClickAdd = useCallback(() => {
    setIsAddSourceModalOpen(true);
    reportPapEvent(
      PAP_Click_AddSourcesButton({
        actionType: 'chat_pane',
        actionSurface: dashActionSurface,
        dashActionSurface,
        actionSurfaceComponent: 'compose_chat_pane',
        featureLine: 'assist',
      }),
    );
  }, [dashActionSurface, reportPapEvent, setIsAddSourceModalOpen]);

  const isConversationAssistantEnabled = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2025_02_11_conversation_assistant'),
  );

  if (!isConversationAssistantEnabled) {
    return <Navigate to={RoutePath.CHAT} replace />;
  }

  if (!conversationId) {
    return null;
  }

  return (
    <ComposeAnalyticsContextProvider>
      <ComposeVoicesContextProvider>
        <AssistTemplatesContextProvider>
          <ChatPageWrapper>
            <ChatGrid
              logChatEvent={() => {
                // TODO logging
              }}
              setExpandRightPane={() => {
                // TODO logging
              }}
              sidePane={
                <ConversationChat
                  variant="default"
                  conversationId={conversationId}
                  handleClickAdd={handleClickAdd}
                  setIsAddSourceModalOpen={setIsAddSourceModalOpen}
                  isAddSourceModalOpen={isAddSourceModalOpen}
                  documentIds={documentIds}
                  model={model}
                  agentId={agentId}
                />
              }
              rightPane={
                null
                // markdownDraft ? (
                //   <ComposeEditorPane
                //     markdownDraft={markdownDraft}
                //     isWaitingForResponse={false}
                //     artifacts={[]}
                //     setMarkdownContent={() => {}}
                //     setDraftConfig={() => {}}
                //     postUpdateDraftWithVoice={() => {}}
                //     postUpdateDraftWithTemplate={() => {}}
                //     messagesHistory={[]}
                //     currentSessionID={''}
                //     setInputContext={() => {}}
                //     postUserMessage={() => {}}
                //   />
                // ) : null
              }
              // showToggle={!!markdownDraft}
            />
          </ChatPageWrapper>
        </AssistTemplatesContextProvider>
      </ComposeVoicesContextProvider>
    </ComposeAnalyticsContextProvider>
  );
});

ConversationAssistantPage.displayName = 'ConversationAssistantPage';
