import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { DownloadLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Close_DashPrivacyModal } from '@mirage/analytics/events/types/close_dash_privacy_modal';
import { PAP_Continue_DashSelfServeOnboarding } from '@mirage/analytics/events/types/continue_dash_self_serve_onboarding';
import { PAP_Select_DashSelfServeOnboardingStepSkip } from '@mirage/analytics/events/types/select_dash_self_serve_onboarding_step_skip';
import { openURL } from '@mirage/service-platform-actions';
import { DESKTOP_DOWNLOAD_URL } from '@mirage/shared/urls';
import i18n from '@mirage/translations';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrivacyModal } from '../flows/PrivacyModal';
import { useFinishOnboarding } from '../flows/utils';
import { OnboardingResponsesProvider } from '../hooks/OnboardingResponsesProvider';
import { useProvisioning } from '../hooks/useProvisioning';
import { useSelfServeOnboardingState } from '../hooks/useSelfServeOnboardingState';
import styles from './SelfServeOnboarding.module.css';
import { SelfServeOnboardingItem } from './SelfServeOnboardingItem';
import { SelfServeOnboardingLayout } from './SelfServeOnboardingLayout';
import { SelfServeOnboardingStep } from './types';

import type { ONBOARDING_VERSION } from '../flows/constants';
import type { OnboardingStep } from '@mirage/analytics/events/enums/onboarding_step';

export enum ActionButtonType {
  Continue = 'continue',
  Skip = 'skip',
  Later = 'later',
  Download = 'download',
}

export type OnboardingStepItem = {
  step: SelfServeOnboardingStep;
  title: string;
  description?: JSX.Element | string;
  secondaryAction?: ActionButtonType;
  primaryActions: ActionButtonType[];
  skipValidation?: boolean;
  hidden?: boolean;
};

type SelfServeOnboardingProps = {
  isAdmin: boolean;
  routeParams?: Record<string, string | undefined>;
  steps: OnboardingStepItem[];
  onboardingVariant: ONBOARDING_VERSION;
  showPrivacyModal: boolean;
  setShowPrivacyModal: (value: boolean) => void;
};

export const SelfServeOnboarding = ({
  isAdmin,
  steps,
  routeParams,
  onboardingVariant,
  showPrivacyModal,
  setShowPrivacyModal,
}: SelfServeOnboardingProps) => {
  const [currentStep, setCurrentStep] = useState<
    OnboardingStepItem | undefined
  >(undefined);
  const { provisionFreeDashTeam } = useProvisioning();
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [continueDisabled, setContinueDisabled] = useState<boolean>(true);
  const [continueLoading, setContinueLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const fromDesktop = params.get('from') === 'desktop';
  // secret url param to use to test against the dev desktop app
  const useDevDesktopApp = params.get('useDevDesktopApp') === 'true';
  const finishOnboarding = useFinishOnboarding(
    reportPapEvent,
    onboardingVariant,
    true,
  );
  const selfServeOnboardingState = useSelfServeOnboardingState();

  const onboardingStepRef = useRef();

  useEffect(() => {
    if (!routeParams) return;
    const { step } = routeParams;
    const index = steps.findIndex((s) => s.step === step);
    if (index >= 0) setCurrentStepIndex(index);
  }, [routeParams, search, steps]);

  useEffect(() => {
    setCurrentStep(steps[currentStepIndex]);
  }, [currentStepIndex, steps]);

  const privacyModalClosed = () => {
    setShowPrivacyModal(false);
    reportPapEvent(
      PAP_Close_DashPrivacyModal({
        featureLine: 'onboarding',
      }),
    );
  };

  const handleContinue = async (skipValidation = false) => {
    setContinueLoading(true);
    let success = true;
    if (!skipValidation && onboardingStepRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      success = await (onboardingStepRef.current as any).submitSurvey();
    }

    setContinueLoading(false);

    // Continue when success is true or undefined
    if (success !== false) {
      let nextStepIndex = currentStepIndex + 1;

      // Skip steps that have `hidden: true`
      while (nextStepIndex < steps.length && steps[nextStepIndex].hidden) {
        nextStepIndex++;
      }

      const isAtTheEnd = nextStepIndex >= steps.length;

      if (isAtTheEnd) {
        finishOnboarding(fromDesktop, useDevDesktopApp);
        return;
      }

      const nextStep = steps[nextStepIndex];
      navigate(getNavigatePathForStep(nextStep.step, isAdmin));
      setCurrentStepIndex(nextStepIndex);
    }
  };

  const onOnboardingFinished = useCallback(() => {
    // TODO: Change how we route on finish based on the JTBD response
    finishOnboarding(fromDesktop, useDevDesktopApp);
  }, [finishOnboarding, fromDesktop, useDevDesktopApp]);

  const handleDownload = useCallback(() => {
    onOnboardingFinished();
    openURL(DESKTOP_DOWNLOAD_URL);
  }, [onOnboardingFinished]);

  const logOnboardingStepSkip = (step: OnboardingStep | null) => {
    if (!step) return;
    reportPapEvent(
      PAP_Select_DashSelfServeOnboardingStepSkip({
        selfServeIsTeamAdmin: isAdmin,
        onboardingStep: step,
        featureLine: 'onboarding',
      }),
    );
  };

  const logOnboardingStepContinue = (step: OnboardingStep | null) => {
    if (!step) return;
    reportPapEvent(
      PAP_Continue_DashSelfServeOnboarding({
        selfServeIsTeamAdmin: isAdmin,
        onboardingStep: step,
        featureLine: 'onboarding',
      }),
    );
  };

  const handleButtonAction = (type: ActionButtonType) => {
    const onboardingStep = convertToOnboardingStep(currentStep?.step);
    switch (type) {
      case ActionButtonType.Skip:
        logOnboardingStepSkip(onboardingStep);
        handleContinue();
        break;
      case ActionButtonType.Later:
        logOnboardingStepSkip(onboardingStep);
        onOnboardingFinished();
        break;
      case ActionButtonType.Continue:
        logOnboardingStepContinue(onboardingStep);
        handleContinue();
        break;
      case ActionButtonType.Download:
        logOnboardingStepContinue(onboardingStep);
        handleDownload();
        break;
      default:
        return;
    }
  };

  const handleStepValidationChange = (valid: boolean) => {
    setContinueDisabled(!valid);
  };

  const getNavigatePathForStep = (
    step: SelfServeOnboardingStep,
    isAdmin: boolean,
  ): string => {
    const routePath = isAdmin
      ? RoutePath.SETUP_ADMIN_SELF_SERVE
      : RoutePath.SETUP_SELF_SERVE;
    return routePath.replace(':step', step);
  };

  const handleProvisionDash = async (isErrorPage?: boolean) => {
    if (isAdmin) {
      const success = await provisionFreeDashTeam(
        selfServeOnboardingState.companyName,
      );
      if (!success) {
        if (!isErrorPage) {
          navigate(
            getNavigatePathForStep(SelfServeOnboardingStep.ERROR, isAdmin),
          );
        }

        return false;
      }
    }
    return true;
  };

  const convertToOnboardingStep = (
    step: SelfServeOnboardingStep | undefined,
  ): OnboardingStep | null => {
    if (!step) return null;
    const stepMapping: Record<SelfServeOnboardingStep, OnboardingStep> = {
      [SelfServeOnboardingStep.COMPANY_INFO]: 'name_and_logo',
      [SelfServeOnboardingStep.PROFILING]: 'profiling',
      [SelfServeOnboardingStep.JTBD]: 'jtbd',
      [SelfServeOnboardingStep.CONNECTORS]: 'create_connectors',
      [SelfServeOnboardingStep.TEAM_INVITE]: 'team_invite',
      [SelfServeOnboardingStep.DOWNLOAD_UPSELL]: 'desktop_download',
      [SelfServeOnboardingStep.LARGE_TEAM]: 'large_team',
      [SelfServeOnboardingStep.ERROR]: 'error',
    };

    return stepMapping[step] || null;
  };

  const hiddenNavigationSteps = [
    SelfServeOnboardingStep.LARGE_TEAM,
    SelfServeOnboardingStep.ERROR,
  ];

  const stepWidths: Partial<Record<SelfServeOnboardingStep, string>> = {
    [SelfServeOnboardingStep.JTBD]: '900px',
    [SelfServeOnboardingStep.TEAM_INVITE]: '600px',
    [SelfServeOnboardingStep.CONNECTORS]: '800px',
    [SelfServeOnboardingStep.DOWNLOAD_UPSELL]: '600px',
    [SelfServeOnboardingStep.LARGE_TEAM]: '900px',
    [SelfServeOnboardingStep.ERROR]: '900px',
  };

  const footerContentItems: Partial<
    Record<SelfServeOnboardingStep, React.ReactNode>
  > = {
    [SelfServeOnboardingStep.CONNECTORS]: (
      <Text size="xsmall" color="subtle" className={styles.legalNote}>
        {i18n.t('self_serve_connectors_legal_note')}
      </Text>
    ),
  };

  const footerContent = currentStep?.step
    ? footerContentItems[currentStep.step]
    : undefined;

  const contentWidth = currentStep?.step
    ? stepWidths[currentStep.step]
    : undefined;

  return (
    <OnboardingResponsesProvider {...selfServeOnboardingState}>
      <SelfServeOnboardingLayout
        title={currentStep?.title}
        description={currentStep?.description}
        width={contentWidth}
        footerContent={footerContent}
        displayNavigation={
          !hiddenNavigationSteps.includes(
            currentStep?.step ?? SelfServeOnboardingStep.COMPANY_INFO,
          )
        }
        actionButtons={
          <SelfServeOnboardingActions
            primaryActions={currentStep?.primaryActions ?? []}
            secondaryAction={currentStep?.secondaryAction}
            onButtonAction={handleButtonAction}
            continueDisabled={continueDisabled}
            continueLoading={continueLoading}
            skipValidation={currentStep?.skipValidation}
          />
        }
        totalSteps={steps.filter((s) => !s.hidden).length}
        currentStep={currentStepIndex}
      >
        <Box display="flex" flexDirection="column" width={'100%'} flexGrow={1}>
          <SelfServeOnboardingItem
            step={currentStep?.step}
            isAdmin={isAdmin}
            onValidationChange={handleStepValidationChange}
            onProvisionDash={handleProvisionDash}
            onContinue={() => handleContinue(true)}
            ref={onboardingStepRef}
          />
        </Box>
        {showPrivacyModal && <PrivacyModal onClose={privacyModalClosed} />}
      </SelfServeOnboardingLayout>
    </OnboardingResponsesProvider>
  );
};

type SelfServeOnboardingActionsProps = {
  secondaryAction?: ActionButtonType;
  primaryActions: ActionButtonType[];
  onButtonAction: (type: ActionButtonType) => void;
  continueDisabled: boolean;
  continueLoading: boolean;
  skipValidation?: boolean;
};

const SelfServeOnboardingActions = ({
  secondaryAction,
  primaryActions,
  onButtonAction,
  continueDisabled,
  continueLoading,
  skipValidation = false,
}: SelfServeOnboardingActionsProps) => {
  const getButtonLabel = (type: ActionButtonType) => {
    switch (type) {
      case ActionButtonType.Continue:
        return i18n.t('continue_button');
      case ActionButtonType.Skip:
        return i18n.t('self_serve_skip');
      case ActionButtonType.Later:
        return i18n.t('self_serve_download_later');
      case ActionButtonType.Download:
        return i18n.t('self_serve_download');
    }
  };

  const getButtonIconStart = (type: ActionButtonType) => {
    switch (type) {
      case ActionButtonType.Download:
        return <UIIcon src={DownloadLine} />;
      default:
        return undefined;
    }
  };

  const getButton = (type: ActionButtonType, disabled: boolean = false) => {
    const isContinueButton =
      type === ActionButtonType.Continue || type === ActionButtonType.Download;
    return (
      <Button
        className={styles.actionButton}
        key={`onboarding-button-${type}`}
        data-testid={`onboarding-button-${type}`}
        size="xlarge"
        variant={isContinueButton ? 'primary' : 'borderless'}
        onClick={() => onButtonAction(type)}
        isLoading={isContinueButton && continueLoading}
        disabled={isContinueButton && !skipValidation ? disabled : false}
        withIconStart={getButtonIconStart(type)}
      >
        <span>{getButtonLabel(type)}</span>
      </Button>
    );
  };

  const secondaryActionCtrl = secondaryAction
    ? getButton(secondaryAction, false)
    : null;

  return (
    <Box display="flex" flexGrow={1} width={'100%'} flexDirection="row">
      <div className={styles.actionButtonSpacers}>{secondaryActionCtrl}</div>
      <Box display="flex" flexDirection="row" className={styles.primaryActions}>
        {primaryActions.map((actionType) =>
          getButton(actionType, continueDisabled),
        )}
      </Box>
      <span className={styles.actionButtonSpacers}></span>
    </Box>
  );
};
