import { postUserMessage } from '@mirage/service-conversation';
import useConversation from '@mirage/service-conversation/hooks/useConversation';
import { convertToChatMessage } from '@mirage/service-conversation/utils/convertToChatMessage';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import type { ChatConversationMessage } from '@mirage/mosaics/Chat/types';

/**
 * This hook is used to initiate new conversation on each new query.
 */
export const useInitialConversation = (query: string, isEnabled: boolean) => {
  const [conversationId, setConversationId] = useState<string>();

  const messages = useConversation(conversationId);

  const composeMessages = useMemo(
    () => messages.map(convertToChatMessage),
    [messages],
  );

  const isWaitingForResponse = useMemo(() => {
    return (
      messages.length === 0 ||
      messages.some((message) => message.status === 'pending')
    );
  }, [messages]);

  const startNewConversation = useCallback((query: string) => {
    const newConversationId = uuid();
    setConversationId(newConversationId);

    postUserMessage({
      conversationId: newConversationId,
      message: query,
      documentIds: [],
    });
  }, []);

  const postFollowUpMessage = useCallback(
    async (query: string) => {
      if (conversationId) {
        await postUserMessage({
          conversationId,
          message: query,
          documentIds: [],
        });
      }
    },
    [conversationId],
  );

  // If flag is on, initiate a new conversation on each new query (unless filters are enabled)
  useEffect(() => {
    if (isEnabled && query) {
      startNewConversation(query);
    }
  }, [query, startNewConversation, isEnabled]);

  return {
    messages: composeMessages,
    conversationId,
    isWaitingForResponse,
    postFollowUpMessage,
  };
};

export type AnswerConversationProps =
  | {
      isEnabled: true;
      messages: ChatConversationMessage[]; // empty when loading
      conversationId: string | undefined; // undefined when loading
      /**
       * Post a follow up message to the conversation
       * @returns A promise that resolves when the message is posted (does NOT wait for its response)
       */
      postFollowUpMessage: (query: string) => Promise<void>;
    }
  | {
      isEnabled: false;
    };

export function useAnswerConversationProps(
  isEnabled: boolean,
  initialConversation: ReturnType<typeof useInitialConversation>,
): AnswerConversationProps {
  const { messages, conversationId, postFollowUpMessage } = initialConversation;
  return useMemo(
    () => ({
      isEnabled,
      messages,
      conversationId,
      postFollowUpMessage,
    }),
    [isEnabled, messages, conversationId, postFollowUpMessage],
  );
}
