import { useFeatureFlagValueBool } from '@mirage/service-experimentation/useFeatureFlagValue';
import { useTypeaheadSearch } from '@mirage/service-typeahead-search/hooks/useTypeaheadSearch';
import { useLocation } from 'react-router-dom';
import { SearchBarWithTypeahead } from '../SearchBarWithTypeahead';
import { useCaptureOmniboxURLParams } from './hooks/useCaptureOmniboxURLParams';
import { useSharedOmniboxProps } from './hooks/useSharedOmniboxProps';
import { Omnibox } from './Omnibox';

import type { OmniboxProps } from './Omnibox';

export function withPersistedOmniboxSurface(
  OmniboxComponent: React.ComponentType<OmniboxProps>,
) {
  return function HOC() {
    const { pathname } = useLocation();
    const isOmniboxEnabled = useFeatureFlagValueBool('dash_2025_03_18_omnibox');
    const isStartPage = pathname === '/';

    const sharedOmniboxProps = useSharedOmniboxProps();

    const typeaheadSearch = useTypeaheadSearch({
      isInstanced: false,
      featureLine: 'search',
    });

    // Integrates typeaheadSearch with the omnibox URL params source of truth
    // This is only applicable to the persisted omnibox surface
    useCaptureOmniboxURLParams(typeaheadSearch);

    // TODO (OSE-5111): create omnibox component that does not have expanded scoped buttons
    // and enable this on non-start pages
    if (!isOmniboxEnabled) {
      return (
        <SearchBarWithTypeahead {...typeaheadSearch} {...sharedOmniboxProps} />
      );
    }

    return (
      <OmniboxComponent
        {...typeaheadSearch}
        {...sharedOmniboxProps}
        isOmniboxEnabled={isOmniboxEnabled}
        isSlimMode={!isStartPage}
      />
    );
  };
}

export const PersistedOmnibox = withPersistedOmniboxSurface(Omnibox);
