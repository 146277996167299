import { IconButton } from '@dropbox/dig-components/buttons';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine, AiChatLine, SearchLine } from '@dropbox/dig-icons/assets';
import { ScopedModeChip } from '@mirage/mosaics/Omnibox/components/Omnibar/ScopedButtonRow/ScopedModeChip';
import { OmniboxScopedMode } from '@mirage/mosaics/Omnibox/types';
import classNames from 'classnames';
import styles from './ScopedButtonRow.module.css';

import type { ScopedModeChipProps } from '@mirage/mosaics/Omnibox/components/Omnibar/ScopedButtonRow/ScopedModeChip';

type ScopedButtonRowProps = {
  onAttachButtonClicked: () => void;
  onScopedButtonClicked: (m: OmniboxScopedMode) => void;
  isResultsOpen: boolean;
};
export const ScopedButtonRow = ({
  onScopedButtonClicked,
  onAttachButtonClicked,
  isResultsOpen,
}: ScopedButtonRowProps) => {
  // Note: will assume that if the results/typeahead is open then scoped rows are not interactable
  // we're disabling this so there's no re-rendering
  const disableButtonRow = isResultsOpen;
  // Note: onClickHandler is omitted since a single shared onClickHandler will be used
  const scopedModeChips: Omit<ScopedModeChipProps, 'onClickHandler'>[] = [
    {
      src: SearchLine,
      modeType: OmniboxScopedMode.FIND,
      chipLabel: 'Find',
    },
    {
      src: AiChatLine,
      modeType: OmniboxScopedMode.ASK,
      chipLabel: 'Ask',
    },
    // TODO add additional scoped action chips after wk2 MVP demo
  ];

  return (
    <Box
      className={classNames(styles.scopedButtonsBoxRow, {
        [styles.disableButtonRow]: disableButtonRow,
      })}
    >
      <IconButton
        variant="opacity"
        shape="circular"
        onClick={onAttachButtonClicked}
        // TEMP disable this until we have functionality for attachements
        disabled={true || disableButtonRow}
      >
        {/* TODO: OSE-5085 add tooltip & a11y label*/}
        <UIIcon src={AddLine} aria-label="attach a document" />
      </IconButton>

      {scopedModeChips.map(({ src, modeType, chipLabel }) => (
        <ScopedModeChip
          key={modeType}
          src={src}
          modeType={modeType}
          chipLabel={chipLabel}
          onClickHandler={() => onScopedButtonClicked(modeType)}
          disabled={disableButtonRow}
        />
      ))}
    </Box>
  );
};
