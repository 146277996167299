import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DashInviteFormSource,
  EmailValidationError,
  type IndividualInviteFormContact,
  InviteFormStatus,
  type InviteWholeTeamFormContact,
} from '../types';
import { isValidInvitee } from '../utils/isValidInvitee';
import { useInviteFormEmailValidation } from './useEmailValidation';
import {
  INITIAL_INVITE_FORM_DATA_STATUS,
  type InitialInviteFormData,
  useInitialInviteFormData,
} from './useInviteFormData';
import {
  type InviteFormSubmissionResult,
  useSubmitInviteForm,
} from './useSubmitInviteForm';

import type { EmailValidator } from './useEmailValidation';

export type UseManagedInviteFormArgs = {
  initialMessage?: string;
  initialEmptyInputCount?: number;
  emailValidator?: EmailValidator;
};

interface UseManagedInviteFormReturnType {
  managedInviteFormProps: ManagedInviteFormProps;
}

export interface UseManagedInviteFormWithInitialDataArgs
  extends Omit<UseManagedInviteFormArgs, 'emailValidator'> {
  dashInviteFormSource: DashInviteFormSource;
  onFormValidationChange?: (valid: boolean) => void;
}

export interface UseManagedInviteFormWithInitialDataReturnType {
  inviteFormStatus: InviteFormStatus;
  submissionResult: InviteFormSubmissionResult | null;
  handleSubmit: () => void;
  managedInviteFormProps: ManagedInviteFormPropsWithInitialData | null;
}

interface ManagedInviteFormProps {
  // States and calculated values
  individualContacts: Readonly<IndividualInviteFormContact[]>;
  teamContacts: Readonly<InviteWholeTeamFormContact[]>;
  validIndividualContactCount: number;
  validTeamContactCount: number;
  initialMessage: string;
  customMessage?: string;
  disabled?: boolean;
  // Handlers
  addEmptyIndividualContact: () => void;
  updateIndividualContact: (contact: IndividualInviteFormContact) => void;
  removeIndividualContact: (contactId: number) => void;
  setTeamContacts: (contacts: InviteWholeTeamFormContact[]) => void;
  clearTeamContacts: () => void;
  updateMessage: (newMessage: string) => void;
}

export interface ManagedInviteFormPropsWithInitialData
  extends ManagedInviteFormProps {
  initialInviteFormData: InitialInviteFormData;
}

export enum INVITE_FORM_SUBMISSION_STATUS {
  CANNOT_SUBMIT = 'cannot_submit',
  CAN_SUBMIT = 'can_submit',
  SUBMITTING = 'submitting',
  SUBMITTED = 'submitted',
}

const useUniqueId = () => {
  const idCounter = useRef(0);
  const getId = useCallback(() => idCounter.current++, []);
  return { getId };
};

/**
 * Returns invite form control states and props that handles managing
 *   invitee data. Logic related to initialization and submission are
 *   handled in `useManagedInviteFormWithInitialData`
 */
export const useManagedInviteForm = ({
  initialMessage = '',
  initialEmptyInputCount = 0,
  emailValidator,
}: UseManagedInviteFormArgs): UseManagedInviteFormReturnType => {
  const { getId } = useUniqueId();

  const messageRef = useRef<string>(initialMessage);

  const [teamContacts, setTeamContacts] = useState<
    Readonly<InviteWholeTeamFormContact[]>
  >([]);
  const [individualContacts, setIndividualContacts] = useState<
    Readonly<IndividualInviteFormContact[]>
  >(() =>
    Array.from({ length: Math.max(initialEmptyInputCount, 0) }, () => ({
      contactId: getId(),
      email: null,
      newRole: null,
    })),
  );

  // When member or admin does not have team members to invite, 4 empty typeahead fields are shown by default
  // However, when data fetch completes and we figure out that inviter actually has team members they could invite,
  // we may need to remove some of the empty typeahead fields (to accomodate the invite-entire-team checkbox component)
  useEffect(() => {
    const currentLength = individualContacts.length;
    const targetLength = Math.max(initialEmptyInputCount, 0);

    if (currentLength < targetLength) {
      setIndividualContacts((prev) => [
        ...prev,
        ...Array.from({ length: targetLength - currentLength }, () => ({
          contactId: getId(),
          email: null,
          newRole: null,
        })),
      ]);
    } else if (currentLength > targetLength) {
      setIndividualContacts((prev) => prev.slice(0, targetLength));
    }
  }, [initialEmptyInputCount]);

  const validIndividualContactCount = useMemo(() => {
    return individualContacts.filter(isValidInvitee).length;
  }, [individualContacts]);

  const validTeamContactCount = useMemo(() => {
    return teamContacts.filter(isValidInvitee).length;
  }, [teamContacts]);

  const clearTeamContacts = useCallback(() => {
    setTeamContacts([]);
  }, []);

  const addEmptyIndividualContact = useCallback(() => {
    setIndividualContacts((prev) => [
      ...prev,
      { contactId: getId(), email: null, newRole: null },
    ]);
  }, [getId]);

  const updateIndividualContact = useCallback(
    async (contact: IndividualInviteFormContact) => {
      // Update first, then validate email if needed and update again
      setIndividualContacts((prev) =>
        prev.map((c) =>
          c.contactId === contact.contactId
            ? {
                ...contact,
                validationError: EmailValidationError.VALIDATION_PENDING,
              }
            : c,
        ),
      );
      const { email } = contact;
      const validationError =
        email && emailValidator ? await emailValidator(email) : undefined;
      setIndividualContacts((prev) => {
        return prev.map((c) =>
          c.contactId === contact.contactId
            ? { ...c, ...contact, validationError }
            : c,
        );
      });
    },
    [emailValidator],
  );

  const removeIndividualContact = useCallback((contactId: number) => {
    setIndividualContacts((prev) =>
      prev.filter((contact) => contact.contactId !== contactId),
    );
  }, []);

  const updateMessage = useCallback((newMessage: string) => {
    messageRef.current = newMessage;
  }, []);

  return {
    managedInviteFormProps: {
      individualContacts,
      teamContacts,
      validIndividualContactCount,
      validTeamContactCount,
      initialMessage,
      customMessage: messageRef.current,
      addEmptyIndividualContact,
      updateIndividualContact,
      removeIndividualContact,
      setTeamContacts,
      clearTeamContacts,
      updateMessage,
    },
  };
};

/**
 * Returns invite form control states and props
 */
export const useManagedInviteFormWithInitialData = ({
  initialMessage,
  initialEmptyInputCount,
  dashInviteFormSource,
  onFormValidationChange,
}: UseManagedInviteFormWithInitialDataArgs): UseManagedInviteFormWithInitialDataReturnType => {
  const { status: initialInviteFormDataStatus, data: initialData } =
    useInitialInviteFormData();

  const inviterHasTeamMembers =
    (initialData?.teamMembers && initialData?.teamMembers.length > 0) ?? false;
  const canInviteAllTeamMembers =
    (initialData?.canInviteAllTeamMembers && inviterHasTeamMembers) ?? false;
  const canAssignRoles = initialData?.canAssignRoles ?? false;
  const canInviteWithOptionalMessage =
    initialData?.canInviteWithOptionalMessage ?? false;
  const initialMessageFiltered = canInviteWithOptionalMessage
    ? initialMessage
    : '';

  // Calculate the number of initial empty input fields to include in the invite form
  // If the invite form is being used in the onboarding flow, then maintain initialEmptyInputCount empty fields (which should be 4)
  // If the invite form is being used in the invite modal, then determine the number of empty fields by whether the invite-entire-team checkbox is shown
  const initialTypeaheadInputFields = useMemo(() => {
    if (!initialData) return 4; // Default to 4 fields while loading
    return dashInviteFormSource === DashInviteFormSource.DASH_HOMEPAGE_MODAL
      ? canInviteAllTeamMembers
        ? initialEmptyInputCount
        : 4
      : initialEmptyInputCount;
  }, [
    initialData,
    dashInviteFormSource,
    canInviteAllTeamMembers,
    initialEmptyInputCount,
  ]);

  const { emailValidator } = useInviteFormEmailValidation({
    knownValidTeamMemberInvitees:
      initialInviteFormDataStatus === INITIAL_INVITE_FORM_DATA_STATUS.READY
        ? initialData.teamMembers
        : [],
  });

  const { managedInviteFormProps } = useManagedInviteForm({
    initialMessage: initialMessageFiltered,
    initialEmptyInputCount: initialTypeaheadInputFields,
    emailValidator,
  });

  const {
    customMessage,
    individualContacts,
    teamContacts,
    validIndividualContactCount,
    validTeamContactCount,
  } = managedInviteFormProps;

  const isValidForm = useMemo(() => {
    if (!initialData) {
      return false;
    }
    const NoValidContact =
      validIndividualContactCount + validTeamContactCount === 0;
    const OverQuota =
      validIndividualContactCount +
        validTeamContactCount +
        initialData.licensesUsed >
      initialData.licenseCount;
    // Unvalidated e-mail are seen as invalid. See notes above on isValidInvitee
    const HasNonEmptyInvalidInvitee = [
      ...individualContacts.filter((c) => !!c.email),
      ...teamContacts,
    ].some((c) => !isValidInvitee(c));
    const isValid = !NoValidContact && !OverQuota && !HasNonEmptyInvalidInvitee;
    return isValid;
  }, [
    initialData,
    validIndividualContactCount,
    validTeamContactCount,
    individualContacts,
    teamContacts,
  ]);

  useEffect(() => {
    onFormValidationChange?.(isValidForm);
  }, [isValidForm, onFormValidationChange]);

  const { handleSubmit, submissionStatus, submissionResult } =
    useSubmitInviteForm({
      canInviteAllTeamMembers,
      canAssignRoles,
      canInviteWithOptionalMessage,
      customMessage,
      dashInviteFormSource,
      individualContacts,
      teamContacts,
      isValidForm,
    });

  const inviteFormStatus: InviteFormStatus = useMemo(() => {
    if (initialInviteFormDataStatus === INITIAL_INVITE_FORM_DATA_STATUS.READY) {
      if (initialData?.licensesUsed >= initialData?.licenseCount) {
        return InviteFormStatus.OVER_QUOTA;
      } else if (submissionStatus === 'submitted') {
        return InviteFormStatus.SUBMITTED;
      } else if (submissionStatus === 'submitting') {
        return InviteFormStatus.SUBMITTING;
      } else if (isValidForm) {
        return InviteFormStatus.CAN_SUBMIT;
      } else {
        return InviteFormStatus.CANNOT_SUBMIT;
      }
    } else {
      // We don't currently have an error state for the invite form, so just keep it as loading if there's an error
      return InviteFormStatus.LOADING;
    }
  }, [initialInviteFormDataStatus, submissionStatus, isValidForm]);

  // Build a new object containing fields we want to pass down to invite form
  // Overwrites canInviteAllTeamMembers based on original value of flag and whether the inviter has team members
  const initialDataFiltered: InitialInviteFormData | null = initialData
    ? {
        licenseCount: initialData.licenseCount,
        licensesUsed: initialData.licensesUsed,
        teamMembers: initialData.teamMembers,
        canInviteAllTeamMembers,
        canAssignRoles,
        canInviteWithOptionalMessage,
      }
    : null;

  return {
    inviteFormStatus,
    submissionResult,
    handleSubmit,
    managedInviteFormProps: initialDataFiltered
      ? {
          ...managedInviteFormProps,
          initialInviteFormData: initialDataFiltered,
          disabled: submissionStatus === 'submitting',
        }
      : null,
  };
};
