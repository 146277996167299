import { Text } from '@dropbox/dig-components/typography';
import { highlightTokenizer } from '@mirage/shared/util/stop-words';
import classNames from 'classnames';
import ReactHighlightWords from 'react-highlight-words';
import styles from './SnippetText.module.css';

const Bold: React.FC = ({ children }) => (
  <Text tagName="span" isBold={true} color="subtle">
    {children}
  </Text>
);

const BoldStandard: React.FC = ({ children }) => (
  <Text tagName="span" isBold={true} color="standard">
    {children}
  </Text>
);

// TEMPORARY hack:
// The server only returns highlights for lexical results.
// We're going to fill in the gaps on the client by highlighting text in semantic results as well
// This means we need to remove the <em> tags from the server response to start from scratch.
// In the future we may let the server handle all of this
// https://jira.dropboxer.net/browse/OTCSI-1427
function removeEmTags(input: string): string {
  return input.replace(/<\/?em>/g, '');
}

export type SnippetTextProps = {
  textToHighlight: string;
  query?: string;
  numOfLines?: number;
  textSize?: 'small' | 'medium' | 'large';
  color?: 'standard' | 'subtle';
};

export const SnippetText = ({
  textToHighlight,
  query = '',
  numOfLines = 2,
  textSize,
  color = 'subtle',
}: SnippetTextProps) => {
  const sanitizedTextToHighlight = removeEmTags(textToHighlight);
  const searchWords = highlightTokenizer(query);

  return (
    <Text color={color} size={textSize}>
      <ReactHighlightWords
        className={classNames(styles.highlightContainer, {
          [styles.lineClamp1]: numOfLines === 1,
        })}
        searchWords={searchWords}
        autoEscape={true}
        textToHighlight={sanitizedTextToHighlight}
        caseSensitive={false}
        highlightTag={color === 'standard' ? BoldStandard : Bold}
      />
    </Text>
  );
};
